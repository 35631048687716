import{ HttpStatusCode } from 'axios';

import { sendRequest } from './api';

import { Feature } from '../models';

export const getFeatures = async (): Promise<Feature[]> => {
    const response = await sendRequest("GET", 'feature');

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data;
}