import React, { useCallback, useEffect, useState } from 'react';

import { 
    Button, 
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Switch,
    FormGroup,
    FormControlLabel
} from '@mui/material';

import { 
  EngagementRule, 
  RegisterCompanyEngagementResult as RegisterCompanyEngagementResultEntity
} from '../../../models';

import { registerCompanyEngagementResult, getEngagementRules } from '../../../adapters';

import { toast } from 'react-toastify';

export const RegisterCompanyEngagementResult = ({
  handleCloseModal,
  selectedCompanyEngagement,
  openModal,
  setActiveFullScreenLoading,
  month,
  year,
  loadCompaniesEngagement
}) => {
  const [engagementRules, setEngagementRules] = useState<EngagementRule[]>([]);
  const [selectedEngagementRule, setSelectedEngagementRule] = useState<EngagementRule | null>(null);
  const [numberReachValue, setNumberReachValue] = useState<number>(0);
  const [numberResultValue, setNumberResultValue] = useState<number>(0);
  const [booleanResultValue, setBooleanResultValue] = useState<boolean>(false);

  const loadEngagementRules = useCallback(async () => {
    setActiveFullScreenLoading(true);

    try {
      const engagementRules = await getEngagementRules(true);

      setEngagementRules(engagementRules);
    } catch (error: any) {
      toast.error("Ocorreu um erro ao carregar as regras de engajamento.");
    }

    setActiveFullScreenLoading(false);
  }, []);

  const handleChangeEngagementRule = (event) => {
    const selectedEngagementRule = engagementRules.find(rule => rule.id === event.target.value) || null;

    setSelectedEngagementRule(selectedEngagementRule);
    setBooleanResultValue(false);
    setNumberReachValue(0);
    setNumberResultValue(0);
  }

  const handleClose = () => {
    setSelectedEngagementRule(null);
    handleCloseModal();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedEngagementRule) {
      toast.error('Selecione uma regra de engajamento.');
      return;
    }

    setActiveFullScreenLoading(true);
    
    try {
      const registerCompanyEngagementResultEntity = new RegisterCompanyEngagementResultEntity();
      registerCompanyEngagementResultEntity.engagementRuleId = selectedEngagementRule.id;
      registerCompanyEngagementResultEntity.month = month;
      registerCompanyEngagementResultEntity.year = year;
      registerCompanyEngagementResultEntity.booleanResultValue = booleanResultValue;
      registerCompanyEngagementResultEntity.numberReachValue = numberReachValue;
      registerCompanyEngagementResultEntity.numberResultValue = numberResultValue;

      await registerCompanyEngagementResult(
        registerCompanyEngagementResultEntity,
        selectedCompanyEngagement.companyId,
      );

      toast.success('Resultado de engajamento registrado com sucesso.');

      handleClose();

      loadCompaniesEngagement(month, year);
    } catch (error: any) {
      toast.error(error.message);
    }

    setActiveFullScreenLoading(false);
  }

  const renderFields = (engagementRule: EngagementRule) => {
    if (engagementRule.type === 'number') {
      return (
        <>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <TextField
            key={`${engagementRule.id}-reach-number`}
            defaultValue={0}
            type="number"
            required
            label="Resultado esperado (Numérico)"
            value={numberReachValue}
            onChange={(event) => setNumberReachValue(parseInt(event.target.value))}
            sx={{ m: 1, minWidth: 400 }}
          />
        </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <TextField
            key={`${engagementRule.id}-result-number`}
            defaultValue={0}
            type="number"
            required
            label="Resultado obtido (Numérico)"
            value={numberResultValue}
            onChange={(event) => setNumberResultValue(parseInt(event.target.value))}
            sx={{ m: 1, minWidth: 400 }}
          />
        </FormControl>
        </>
      )
    } else if (engagementRule.type === 'boolean') {
      return (
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <FormGroup key={`${engagementRule.id}`}>
            <FormControlLabel 
              control={
                <Switch  
                  key={`${engagementRule.id}-result-boolean`}
                  checked={booleanResultValue}
                  onChange={(event) => setBooleanResultValue(event.target.checked)}
                />
              } 
              label="Resultado obtido (Verdadeiro/Falso)"
              sx={{ m: 1, minWidth: 400 }}
            />
          </FormGroup>
        </FormControl>
      )
    }
 }

  useEffect(() => {
    loadEngagementRules();
  }, [loadEngagementRules]);

  return (
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="dialogTitleRegisterEngagementResult"
        aria-describedby="dialogDescriptionRegisterEngagementResult"
      >
        <DialogTitle id="dialogTitleRegisterEngagementResult">
          Cadastrar resultado de engajamento da empresa
        </DialogTitle>
        <form className="formAddConnector" onSubmit={handleSubmit} >
          <DialogContent
            id="dialogDescriptionRegisterEngagementResult"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          > 
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel 
                id="selectEngagementRuleLabel"
                sx={{ m: 1, minWidth: 400}}
              >
                Regra de Engajamento
              </InputLabel>
              <Select
                  required
                  labelId="selectEngagementRuleLabel"
                  id="selectEngagementRule"
                  value={selectedEngagementRule !== null ? selectedEngagementRule.id : ''}
                  label="Regra de Engajamento"
                  onChange={handleChangeEngagementRule}
                  sx={{ m: 1, minWidth: 400}}
              >
                  {engagementRules.map(rule => (
                      <MenuItem key={rule.id} value={rule.id}>{rule.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            {selectedEngagementRule && renderFields(selectedEngagementRule)}
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button 
              variant="contained" 
              type='submit'
              autoFocus
              disabled={!selectedEngagementRule}
            >
              Cadastrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
  );
}