import{ HttpStatusCode } from 'axios';

import { sendRequest } from './api';

import { RegisterUserEntity } from '../models';

export const registerUser = async (user: RegisterUserEntity): Promise<void> => {
    const response = await sendRequest("POST", 'user/register', user);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Created && response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao registrar o usuário. Tente novamente mais tarde.");
    }
}