import React, { useState } from 'react';

import dayjs from 'dayjs';

import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField 
} from '@mui/material';

import { DateField, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

export const EditCompany = ({
    handleCloseModal,
    handleEditCompany,
    selectedCompany,
    openModal
}) => {
  const [companyOwnerEmail, setCompanyOwnerEmail] = useState(selectedCompany.companyOwnerEmail);
  const [companyName, setCompanyName] = useState(selectedCompany.companyName);

  const handleSubmit = (event) => {
    event.preventDefault();
    
    handleEditCompany(
      selectedCompany.companyId,
      companyName,
      companyOwnerEmail
    );
  }

  return (
      <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="dialogTitleEditCompany"
          aria-describedby="dialogDescriptionEditCompany"
        >
      <DialogTitle id="dialogTitleEditCompany">
        {"Editar empresa"}
      </DialogTitle>
      <form className="formEditCompany" onSubmit={handleSubmit} >
        <DialogContent
          id="dialogDescriptionEditCompany"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
            <TextField 
                required
                id="email" 
                label="Email do responsável"
                type='email'
                variant="outlined" 
                value={companyOwnerEmail}
                onChange={(event) => setCompanyOwnerEmail(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="companyName" 
                  label="Nome da empresa"
                  type='text'
                  variant="outlined" 
                  value={companyName}
                  onChange={(event) => setCompanyName(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
            <TextField 
                disabled
                id="companyName" 
                label="Login da empresa"
                variant="outlined" 
                value={selectedCompany.companyUserName}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateField
                    disabled
                    format='DD/MM/YYYY'
                    label="Período de retenção de dados" 
                    value={dayjs(selectedCompany.retetionPeriodData)}
                />
            </LocalizationProvider>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button
            type='submit' 
            variant="contained" 
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}