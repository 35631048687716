import{ HttpStatusCode } from 'axios';

import { sendRequest } from './api';

import { 
    CompanyEngagement, 
    CompanyEngagementResult, 
    RegisterCompanyEngagementResult,
    EngagementRule,
    RegisterEngagementRule
} from '../models';

export const getCompaniesEngagement = async (month: number, year: number): Promise<CompanyEngagement[]> => {
    const response = await sendRequest("GET", `engagement/company?month=${month}&year=${year}`);

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data.data;
}

export const getCompanyEngagementResult = async (month: number, year: number, companyId: string): Promise<CompanyEngagementResult[]> => {
    const response = await sendRequest("GET", `engagement/company/${companyId}/result?month=${month}&year=${year}`);

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data;
}

export const deleteCompanyEngagementResult = async (companyEngamentId: string): Promise<void> => {
    const response = await sendRequest("DELETE", `engagement/company/result/${companyEngamentId}`);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao deletar o resultado do engajamento. Tente novamente.");
    }

    return;
}

export const registerCompanyEngagementResult = async (request: RegisterCompanyEngagementResult, companyId: string): Promise<void> => {
    const response = await sendRequest("POST", `engagement/company/${companyId}/result`, request);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Created) {
        throw new Error("Ocorreu um erro ao registrar o resultado do engajamento. Tente novamente.");
    }

    return;
}

export const getEngagementRules = async (active: boolean | null = null): Promise<EngagementRule[]> => {
    const response = await sendRequest("GET", `engagement/rule${active !== null ? `?active=${active}` : ""}`);

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data;
}

export const registerEngagementRule = async (request: RegisterEngagementRule): Promise<void> => {
    const response = await sendRequest("POST", `engagement/rule`, request);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Created) {
        throw new Error("Ocorreu um erro ao criar a regra de engajamento. Tente novamente.");
    }

    return;
}

export const updateEngagementRulePoints = async (ruleId: string, points: number): Promise<void> => {
    const response = await sendRequest("PATCH", `engagement/rule/${ruleId}/points/${points}`);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao atualizar os pontos da regra de engajamento. Tente novamente.");
    }

    return;
}

export const updateEngagementRuleActive = async (ruleId: string, active: boolean): Promise<void> => {
    const response = await sendRequest("PATCH", `engagement/rule/${ruleId}/active/${active}`);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao atualizar o status da regra de engajamento. Tente novamente.");
    }

    return;
}