import{ HttpStatusCode } from 'axios';

import { LoggedUser } from '../models';
import { api } from './api';

export const signIn = async (email: string, password: string): Promise<LoggedUser> => {
    const params = new URLSearchParams();
    params.append('username', email);
    params.append('password', password);
    
    const response = await api.post('authentication/signin', params, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
    })

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data;
}

export const validateToken = async (): Promise<void> => {
    const response = await api.get('authentication/validate/token')

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }
}