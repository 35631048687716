import * as React from 'react';
import { Autocomplete, TextField, Chip } from '@mui/material';

interface MultiNumberInputProps {
    value: number[];
    label: string;
    onChange?: (value: number[]) => void;
    required?: boolean;
    disabled?: boolean;
}

export const MultiNumberInput: React.FC<MultiNumberInputProps> = ({
    value, label, onChange, required, disabled
}) => {
  const convertValueToNumber = (value: string[]) => {
    return value ? value.map((val) => parseInt(val)) : [];
  }

  const convertValueToString = (value: number[]) => {
    return value ? value.map((val) => val.toString()) : [];
  } 
  
  const handleChange = (event: React.ChangeEvent<{}>, newValue: string[]) => {
    const validNumbers = newValue.filter((val) => /^\d+$/.test(val));
    if (onChange) {
      onChange(convertValueToNumber(validNumbers));
    }
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      options={[]}
      value={convertValueToString(value)}
      onChange={handleChange}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
        ))
      }
      disabled={disabled}
      renderInput={(params) => {
        const isFirstField = value && value.length > 0;
        return (
          <TextField
            {...params}
            variant="outlined"
            label={label}
            onKeyDown={(event) => {
              if (!/^\d*$/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete') {
                event.preventDefault();
              }
            }}
            required={required && !isFirstField}
          />
        );
      }}
    />
  );
};