import { toast } from 'react-toastify';

import{ HttpStatusCode } from 'axios';

import { sendRequest } from './api';

import { CompanyEntity, CompanySettings, RegisterCompanyEntity, CompanyUserModule } from '../models';

export const getCompanies = async (): Promise<CompanyEntity[]> => {
    const response = await sendRequest("GET", 'company');

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data;
}

export const updateCompany = async (id: string, newCompanyName: string, newCompanyOwnerEmail: string): Promise<void> => {
    const response = await sendRequest(
        "PUT", 
        `company/edit/${id}`, 
        { companyName: newCompanyName, companyOwnerEmail: newCompanyOwnerEmail }
    );

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao atualizar a empresa. Tente novamente mais tarde.");
    }
}

export const deleteCompany = async (id: string): Promise<void> => {
    const response = await sendRequest("DELETE", `company/delete/${id}`);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.NoContent) {
        throw new Error("Ocorreu um erro ao deletar a empresa. Tente novamente mais tarde.");
    }
}

export const registerCompany = async (company: RegisterCompanyEntity): Promise<void> => {
    const response = await sendRequest("POST", 'company/register', company);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Created && response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao registrar a empresa. Tente novamente mais tarde.");
    }
}

export const addConnector = async (id: string, connectorName: string, settings: CompanySettings): Promise<void> => {
    const response = await sendRequest("POST", `company/${id}/connector`, { connectorName, settings });

    if (response.status === HttpStatusCode.BadRequest) {
        response.data.errors.forEach((error_message: string) => toast.error(error_message, { autoClose: false }));
    }

    if (response.status !== HttpStatusCode.Created && response.status !== HttpStatusCode.Ok) {
        throw new Error("Não foi possível adicionar o conector. Verifique os dados e tente novamente.");
    }
}

export const enableOrDisableCompanyFeature = async (id: string, featureId: string, enabled: boolean): Promise<void> => {
    const response = await sendRequest("PATCH", `company/${id}/feature/enable?feature_id=${featureId}&enabled=${enabled}`);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.NoContent) {
        throw new Error("Ocorreu um erro ao tentar habilitar ou desabilitar a funcionalidade da empresa. Tente novamente mais tarde.");
    }
}

export const getCompanyConnectorUserModule = async (id: string, connectorName: string): Promise<CompanyUserModule|null> => {
    const response = await sendRequest("GET", `company/${id}/connector/user_module?connector_name=${connectorName}`);

    if (response.status === HttpStatusCode.BadRequest) {
        return null
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao buscar o módulo do conector. Tente novamente mais tarde.");
    }

    return response.data;
}