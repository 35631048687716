import axios, { AxiosResponse, Method} from 'axios';

const api = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});

export const sendRequest = async (method: Method, uri: string, data: object | undefined = undefined): Promise<AxiosResponse> => {
    const response = await api(uri, {
        method: method,
        headers: {
            "Content-Type": "application/json",
        },
        data: data
    });

    return response
}

export { api };