import React, { useContext, useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import { MenuList, MenuItem, ListItemText, AppBar, Toolbar, Button, Avatar } from '@mui/material';

import { AuthContext } from "../../contexts";

import { UserRoleType } from "../../models";

import { appPathPrivateRoutesDict } from "../../routes";

import './index.css';

export const Menu = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { handleLogout, user } = useContext(AuthContext);
    const [isAdminUser, setIsAdminUser] = useState(false);
    const [selectedMenuItem, setSelectedMenuItem] = useState(appPathPrivateRoutesDict.home);
    const [profileLetter, setProfileLetter] = useState('');

    useEffect(() => { 
        if (!user) return;
        
        setIsAdminUser(user.role === UserRoleType.ADMIN);
        setProfileLetter(user.name.charAt(0));
    }, [user]);

    useEffect(() => {
        const path = location.pathname;

        setSelectedMenuItem(path);

    }, [location.pathname]);

    const handleMenuItemSelected = (path: string) => {
        setSelectedMenuItem(path);

        navigate(path);
    }
    
    return (
        <AppBar component="nav" sx={{ backgroundColor: "rgb(255,255,255)", color: "#5f6368" }}>
            <Toolbar>
                <div className="menuLogo">
                    <img src="https://static.wixstatic.com/media/7cd449_4f83308cd7594c0f8958136504fb9b25~mv2.png/v1/crop/x_0,y_0,w_423,h_138/fill/w_178,h_58,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/logo-biso_PM_tagline_edited.png" alt="Logo Biso" />
                    <p>Backoffice</p>
                </div>
                <MenuList
                    sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}
                >
                    <MenuItem
                        selected={selectedMenuItem === appPathPrivateRoutesDict.home}
                        onClick={() => handleMenuItemSelected(appPathPrivateRoutesDict.home)}
                    >
                        <ListItemText>Introdução</ListItemText>
                    </MenuItem>
                    <MenuItem
                        selected={selectedMenuItem === appPathPrivateRoutesDict.registerCompany}
                        onClick={() => handleMenuItemSelected(appPathPrivateRoutesDict.registerCompany)}
                    >
                        <ListItemText>Cadastrar empresa</ListItemText>
                    </MenuItem>
                    <MenuItem
                        selected={selectedMenuItem === appPathPrivateRoutesDict.consultCompany}
                        onClick={() => handleMenuItemSelected(appPathPrivateRoutesDict.consultCompany)}
                    >
                        <ListItemText>Consultar empresas</ListItemText>
                    </MenuItem>
                    {isAdminUser && (
                        <MenuItem
                            selected={selectedMenuItem === appPathPrivateRoutesDict.registerUser}
                            onClick={() => handleMenuItemSelected(appPathPrivateRoutesDict.registerUser)}
                        >
                            <ListItemText>Criar usuário</ListItemText>
                        </MenuItem>
                    )}
                    <MenuItem
                        selected={selectedMenuItem === appPathPrivateRoutesDict.partner}
                        onClick={() => handleMenuItemSelected(appPathPrivateRoutesDict.partner)}
                    >
                        <ListItemText>Parceiros</ListItemText>
                    </MenuItem>
                    <MenuItem
                        selected={selectedMenuItem === appPathPrivateRoutesDict.engagement}
                        onClick={() => handleMenuItemSelected(appPathPrivateRoutesDict.engagement)}
                    >
                        <ListItemText>Engajamento</ListItemText>
                    </MenuItem>
                </MenuList>

                <Avatar sx={{ bgcolor: "rgb(66,133,244)" }}>{profileLetter}</Avatar>
                <Button 
                    onClick={handleLogout}
                    sx={{ marginLeft: 'auto', borderColor: 'white' }}
                >
                    Sair
                </Button>
            </Toolbar>
        </AppBar>
    );
}