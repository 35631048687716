export class RegisterUserEntity {
    public name: string = ''
    public email: string = ''
    public password: string = ''
    public role: string = ''

    constructor({ name, email, password, role }) {
        this.name = name
        this.email = email
        this.password = password
        this.role = role
    }
}