import React, { useState, useCallback, useEffect } from 'react';

import { toast } from 'react-toastify';

import { 
    Button, 
    CircularProgress, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TablePagination, 
    TableRow,
    TextField
} from '@mui/material';

import { Delete, Edit } from '@mui/icons-material';

import { Container, FullScreenLoading } from "../../components";
import { PartnerEntity, RegisterPartnerEntity } from '../../models';
import { getPartners, registerPartner, updatePartner, deletePartner } from '../../adapters';

import { DeletePartner } from './DeletePartner';
import { EditPartner } from './EditPartner';
import { RegisterPartner } from './RegisterPartner';

import './index.css';

export const Partner = () => {
    const PAGE_INITIAL_STATE = 0;
    const ROWS_PER_PAGE_INITIAL_STATE = 10;
    const [page, setPage] = useState(PAGE_INITIAL_STATE);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    const [partners, setPartners] = useState<PartnerEntity[]>([]);
    const [rows, setRows] = useState<PartnerEntity[]>([]);
    const [searched, setSearched] = useState<string>('');
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openRegisterModal, setOpenRegisterModal] = useState<boolean>(false);
    const [selectedPartner, setSelectedPartner] = useState<PartnerEntity| null>(null);
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);

    const isURLValid = (url: string): boolean => {
        try {
          new URL(url);
          return true;
        } catch (error) {
          return false;
        }
    }

    const isValidPartnerData = (data: RegisterPartnerEntity): boolean => {
        const isStringNotEmptyOrWhitespace = (str: string): boolean => {
            return /\S/.test(str);
        }

        if (!isStringNotEmptyOrWhitespace(data.name) || 
            !isStringNotEmptyOrWhitespace(data.description) || 
            !isStringNotEmptyOrWhitespace(data.expertise) || 
            !isStringNotEmptyOrWhitespace(data.commercialBenefit) || 
            !isStringNotEmptyOrWhitespace(data.imageUrl) || 
            !isStringNotEmptyOrWhitespace(data.redirect)
        ) {
            toast.error('Os dados informados não são validos. Por favor revise os campos.');

            return false;
        }

        if (!isURLValid(data.imageUrl) || !isURLValid(data.redirect)) {
            toast.error('Um ou mais links informados não são válidos. Por favor revise os campos.');

            return false;
        }

        return true;
    }

    const loadPartners = useCallback(async () => {
        setLoading(true);

        try {
            const partners = await getPartners();
            
            setPage(PAGE_INITIAL_STATE);
            setRowsPerPage(ROWS_PER_PAGE_INITIAL_STATE);
            setSearched('');

            setPartners(partners);
            setRows(partners);
        } catch (error) {
            toast.error('Ocorreu um erro ao carregar os parceiros. Tente novamente mais tarde.');
        }

        setLoading(false);
    }, []);

    const handleDeletePartner = async (id: string) => {
        setActiveFullScreenLoading(true);

        try {
            await deletePartner(id);

            toast.success('Parceiro excluído com sucesso.');

            handleCloseModal()

            loadPartners();
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    }

    const handleEditPartner = async (id: string, data: RegisterPartnerEntity) => {
        setActiveFullScreenLoading(true)

        try {
            await updatePartner(id, data);

            toast.success('Parceiro atualizado com sucesso.');
            
            handleCloseModal()

            loadPartners();
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    }

    const handleRegisterPartner = async (data: RegisterPartnerEntity) => {
        setActiveFullScreenLoading(true)

        try {
            await registerPartner(data);

            toast.success('Parceiro cadastrado com sucesso.');
            
            handleCloseModal()

            loadPartners();
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    }

    const handleOpenDeleteModal = (partner: PartnerEntity) => {
        setSelectedPartner(partner);
        setOpenDeleteModal(true);
    }

    const handleOpenEditModal = (partner: PartnerEntity) => {
        setSelectedPartner(partner);
        setOpenEditModal(true);
    }

    const handleOpenRegisterModal = () => {
        setOpenRegisterModal(true);
    }

    const handleCloseModal = () => {
        setOpenDeleteModal(false);
        setOpenEditModal(false);
        setOpenRegisterModal(false);
        setSelectedPartner(null);
    }

    const handleChangePage = (_, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onChangeSearch = (searchedVal: string) => {
        if (searchedVal === '') {
            setRows(partners);
            return;
        }

        const filteredRows = partners.filter((row) => {
            return row.name.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        loadPartners();
    }, [loadPartners]);

    useEffect(() => {
        onChangeSearch(searched)
    }, [searched]);

    const columns = [
        { id: 'name', label: 'Nome do parceiro', minWidth: 700 },
        {
          id: 'actions',
          label: 'Ações'
        }
    ];

    return (
        <Container>
            <div className='boxModel'>
                <h2 className='titlePartner'>Parceiros</h2>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <div className='actionsBar'>
                        <TextField 
                            id="outlined-search" 
                            label="Pesquisar parceiro"
                            type="search"
                            value={searched}
                            sx={{ width: '30%', margin: '10px' }}
                            onChange={(e) => setSearched(e.target.value)}
                        />
                        <Button variant="contained" className='registerPartnerButton' onClick={handleOpenRegisterModal} autoFocus>
                            Cadastrar parceiro
                        </Button>
                    </div>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading && rows.length > 0 &&  rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                {columns.map((column) => {

                                                    if (column.id === 'actions') {
                                                        return (
                                                            <TableCell key={column.id} sx={{ display: 'flex', justifyContent: 'space-around'}}>
                                                                <Button 
                                                                    variant="outlined"
                                                                    onClick={() => handleOpenEditModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <Edit/>
                                                                </Button>
                                                                <Button 
                                                                    variant="outlined" 
                                                                    color="error"
                                                                    onClick={() => handleOpenDeleteModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <Delete/>
                                                                </Button>
                                                            </TableCell>
                                                        );
                                                    }
                                                    
                                                    return (
                                                        <TableCell key={column.id}>{row[column.id]}</TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                                {!loading && rows.length == 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            Nenhum parceiro encontrado.
                                        </TableCell>
                                    </TableRow>
                                )}
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            <DeletePartner
                handleCloseModal={handleCloseModal}
                handleDeletePartner={handleDeletePartner}
                selectedPartner={selectedPartner}
                openModal={openDeleteModal}
            />
            {selectedPartner && <EditPartner
                handleCloseModal={handleCloseModal}
                isValidPartnerData={isValidPartnerData}
                handleEditPartner={handleEditPartner}
                selectedPartner={selectedPartner}
                openModal={openEditModal}
            />}
            <RegisterPartner
                handleCloseModal={handleCloseModal}
                isValidPartnerData={isValidPartnerData}
                handleRegisterPartner={handleRegisterPartner}
                openModal={openRegisterModal}
                resetFields={openRegisterModal}
            />
            <FullScreenLoading open={activeFullScreenLoading} />
        </Container>
    )
}