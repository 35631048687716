import React, { useState, useCallback, useEffect, useMemo } from 'react';

import { 
    Button, 
    CircularProgress, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead,
    TableRow,
    TextField,
    MenuItem,
    Select,
    SelectChangeEvent,
    InputLabel,
    FormControl,
    TableSortLabel,
    Box
} from '@mui/material';

import { visuallyHidden } from '@mui/utils';

import 'dayjs/locale/pt-br';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { AutoGraph, PostAdd, Add, Edit, BorderAll, InsertDriveFile, Checklist } from '@mui/icons-material';

import { Container, FullScreenLoading } from "../../components";

import { CompanyEngagement } from '../../models';

import { getCompaniesEngagement } from '../../adapters';

import { Order, getComparator, stableSort} from '../../utils';

import { EditEngagementRule } from './EditEngagementRule';
import { RegisterEngagementRule } from './RegisterEngagementRule';
import { CompanyEngagementResult } from './CompanyEngagementResult';
import { CompanyEngagementGoalsByChannel } from './CompanyEngagementGoalsByChannel';
import { RegisterCompanyEngagementResult } from './RegisterCompanyEngagementResult';
import { Matrix } from './Matrix';

import { toast } from 'react-toastify';

import './index.css';

interface CompanyEngagementRow {
    companyId: string;
    companyName: string;
    classification: string;
    totalPoints: number;
    revenueGoal: number;
    revenueResult: number;
    revenueAchieved: number;
    revenueAchievedCurrentMonthUntilToday: number;
    sessionsGoal: number;
    sessionsResult: number;
    sessionsAchieved: number;
    sessionsAchievedCurrentMonthUntilToday: number;
    conversionRateGoal: number;
    conversionRateResult: number;
    conversionRateAchieved: number;
    conversionRateAchievedCurrentMonthUntilToday: number;
    averageTicketGoal: number;
    averageTicketResult: number;
    averageTicketAchieved: number;
    averageTicketAchievedCurrentMonthUntilToday: number;
}

export const Engagement = () => {
    const [loading, setLoading] = useState(true);
    const [companiesEngagement, setCompaniesEngagement] = useState<CompanyEngagement[]>([]);
    const [rows, setRows] = useState<CompanyEngagementRow[]>([]);
    const [searched, setSearched] = useState<string>('');
    const [selectedCompanyEngagement, setSelectedCompanyEngagement] = useState<CompanyEngagementRow| null>(null);
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);
    const [loadingExportButton, setLoadingExportButton] = useState<boolean>(false);
    const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs(new Date()));
    const [selectedClassification, setSelectedClassification] = useState<string[]>([]);
    const [selectedChannel, setSelectedChannel] = useState<string>('allChannels');
    const [openCompanyEngagementResultModal, setOpenCompanyEngagementResultModal] = useState<boolean>(false);
    const [openRegisterCompanyEngagementResultModal, setOpenRegisterCompanyEngagementResultModal] = useState<boolean>(false);
    const [openCompanyEngagementGoalsByChannelModal, setOpenCompanyEngagementGoalsByChannelModal] = useState<boolean>(false);
    const [openMatrixModal, setOpenMatrixModal] = useState<boolean>(false);
    const [openRegisterEngagementRuleModal, setOpenRegisterEngagementRuleModal] = useState<boolean>(false);
    const [openEditEngagementRuleModal, setOpenEditEngagementRuleModal] = useState<boolean>(false);
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<keyof CompanyEngagementRow>('totalPoints');

    const CLASSIFCATION_OPTIONS = [
        "INCIPIENT", 
        "INEFETIVO", 
        "HIGH POTENTIAL",
        "HIGH PERFORMER"
    ];

    const CHANNELS_OPTIONS = [
        {id: "allChannels", label: "Todos os canais"},
        {id: "paidMedia", label: "Mídia"},
        {id: "direct", label: "Direto"},
        {id: "crm", label: "CRM"},
        {id: "seo", label: "SEO"}
    ]

    const handleRequestSort = (
        _,
        property: keyof CompanyEngagementRow,
    ) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const sortedRows = useMemo(
        () =>
          stableSort(rows, getComparator(order, orderBy)),
        [order, orderBy, rows],
    );

    const formatValueToMoney = (value) => {
        const formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
        });
    
        return formatter.format(value);
    }
    
    const formatValueToNumber = (value) => {
      const formatter = new Intl.NumberFormat('pt-BR');
    
      return formatter.format(value);
    }
    
    const formatValueToPercentage = (value) => {
      const formatter = new Intl.NumberFormat('pt-BR');
    
      return `${formatter.format(value)}%`;
    }    

    const defineCellBackgroundColor = (value) => {
        if (value === 0) {
            return '#fff'
        } else if (value > 0) {
            return 'rgba(89, 172, 92, 0.2)'
        } else if (value >= -50) {
            return "rgba(212, 176, 81, 0.2)"
        } else {
            return 'rgba(203, 118, 118, 0.2)'
        }
    }

    const getFormattedRowData = (companiesEngagement: CompanyEngagement[]) => {
        const formattedData = companiesEngagement.map(companyEngagement => {
            return {
                companyId: companyEngagement.companyId,
                companyName: companyEngagement.companyName,
                classification: companyEngagement.classification,
                totalPoints: companyEngagement.totalPoints,
                revenueGoal: companyEngagement.goals.allChannels.revenue.goal,
                revenueResult: companyEngagement.goals.allChannels.revenue.result,
                revenueAchieved: companyEngagement.goals.allChannels.revenue.achieved,
                revenueAchievedCurrentMonthUntilToday: companyEngagement.goals.allChannels.revenue.achievedCurrentMonthUntilToday,
                sessionsGoal: companyEngagement.goals.allChannels.sessions.goal,
                sessionsResult: companyEngagement.goals.allChannels.sessions.result,
                sessionsAchieved: companyEngagement.goals.allChannels.sessions.achieved,
                sessionsAchievedCurrentMonthUntilToday: companyEngagement.goals.allChannels.sessions.achievedCurrentMonthUntilToday,
                conversionRateGoal: companyEngagement.goals.allChannels.conversionRate.goal,
                conversionRateResult: companyEngagement.goals.allChannels.conversionRate.result,
                conversionRateAchieved: companyEngagement.goals.allChannels.conversionRate.achieved,
                conversionRateAchievedCurrentMonthUntilToday: companyEngagement.goals.allChannels.conversionRate.achievedCurrentMonthUntilToday,
                averageTicketGoal: companyEngagement.goals.allChannels.averageTicket.goal,
                averageTicketResult: companyEngagement.goals.allChannels.averageTicket.result,
                averageTicketAchieved: companyEngagement.goals.allChannels.averageTicket.achieved,
                averageTicketAchievedCurrentMonthUntilToday: companyEngagement.goals.allChannels.averageTicket.achievedCurrentMonthUntilToday
            };
        });

        return formattedData;
    };

    const loadCompaniesEngagement = useCallback(async (month: number, year: number) => {
        setLoading(true);
        setSelectedClassification([]);
        setSearched('');

        try {
            const response = await getCompaniesEngagement(month, year);
            setSearched('');

            setCompaniesEngagement(response);
            setRows(getFormattedRowData(response));
        } catch (error) {
            toast.error('Ocorreu um erro ao carregar as empresas. Tente novamente mais tarde.');
        } 

        setLoading(false);
    }, []);

    const handleCloseModal = () => {
        setSelectedCompanyEngagement(null);
        setOpenCompanyEngagementResultModal(false);
        setOpenRegisterCompanyEngagementResultModal(false);
        setOpenCompanyEngagementGoalsByChannelModal(false);
        setOpenMatrixModal(false);
        setOpenRegisterEngagementRuleModal(false);
        setOpenEditEngagementRuleModal(false);
    }

    const handleOpenCompanyEngagementResultModal = (companyEngagement: CompanyEngagementRow) => {
        setSelectedCompanyEngagement(companyEngagement);
        setOpenCompanyEngagementResultModal(true);
    }

    const handleOpenRegisterCompanyEngagementResultModal = (companyEngagement: CompanyEngagementRow) => {
        setSelectedCompanyEngagement(companyEngagement);
        setOpenRegisterCompanyEngagementResultModal(true);
    }

    const handleOpenCompanyEngagementGoalsByChannelModal = (companyEngagement: CompanyEngagementRow) => {
        setSelectedCompanyEngagement(companyEngagement);
        setOpenCompanyEngagementGoalsByChannelModal(true);
    }

    const onAcceptDateFilter =async (newDate: Dayjs | null) => {
        if (!newDate) {
            return;
        }

        const month = newDate.month() + 1;
        const year = newDate.year();
        await loadCompaniesEngagement(month, year);
    };

    const onChangeSearch = (searchedVal: string) => {
        setSelectedClassification([]);
        
        if (searchedVal === '') {
            const formattedRows = getFormattedRowData(companiesEngagement);
            setRows(getRowsWithSomeGoalsAndResultsByChannel(formattedRows, selectedChannel));
            return;
        }

        const filteredRows = companiesEngagement.filter((row) => {
            return row.companyName.toLowerCase().includes(searchedVal.toLowerCase());
        });

        const formattedRows = getFormattedRowData(filteredRows);
        setRows(getRowsWithSomeGoalsAndResultsByChannel(formattedRows, selectedChannel));
    };

    const handleChangeClassification = (event: SelectChangeEvent<typeof selectedClassification>) => {
        const {
            target: { value },
        } = event;

        const classifications = typeof value === 'string' ? value.split(',') : value;
        setSelectedClassification(classifications);

        if (!classifications || classifications.length === 0) {
            const formattedRows = getFormattedRowData(companiesEngagement);
            setRows(getRowsWithSomeGoalsAndResultsByChannel(formattedRows, selectedChannel));
            return;
        }

        const filteredRows = companiesEngagement.filter((row) => {
            return classifications.includes(row.classification);
        });

        const formattedRows = getFormattedRowData(filteredRows);
        setRows(getRowsWithSomeGoalsAndResultsByChannel(formattedRows, selectedChannel));
    };

    const handleChangeChannel = (event: SelectChangeEvent<typeof selectedChannel>) => {
        const {
            target: { value },
        } = event;
        setSelectedChannel(value);

        if (!value) {
            setRows(rows);
            return;
        }

        setRows(getRowsWithSomeGoalsAndResultsByChannel(rows, value));
    };

    const getRowsWithSomeGoalsAndResultsByChannel = (rows: CompanyEngagementRow[], channel: string) => {
        const companiesIds = rows.map(row => row.companyId)
        const companiesEngagementFiltered = companiesEngagement.filter(company => companiesIds.includes(company.companyId))

        return companiesEngagementFiltered.map(companyEngagement => {
            return {
                companyId: companyEngagement.companyId,
                companyName: companyEngagement.companyName,
                companyOwnerEmail: companyEngagement.companyOwnerEmail,
                classification: companyEngagement.classification,
                totalPoints: companyEngagement.totalPoints,
                revenueGoal: companyEngagement.goals[channel].revenue.goal,
                revenueResult: companyEngagement.goals[channel].revenue.result,
                revenueAchieved: companyEngagement.goals[channel].revenue.achieved,
                revenueAchievedCurrentMonthUntilToday: companyEngagement.goals[channel].revenue.achievedCurrentMonthUntilToday,
                sessionsGoal: companyEngagement.goals[channel].sessions.goal,
                sessionsResult: companyEngagement.goals[channel].sessions.result,
                sessionsAchieved: companyEngagement.goals[channel].sessions.achieved,
                sessionsAchievedCurrentMonthUntilToday: companyEngagement.goals[channel].sessions.achievedCurrentMonthUntilToday,
                conversionRateGoal: companyEngagement.goals[channel].conversionRate.goal,
                conversionRateResult: companyEngagement.goals[channel].conversionRate.result,
                conversionRateAchieved: companyEngagement.goals[channel].conversionRate.achieved,
                conversionRateAchievedCurrentMonthUntilToday: companyEngagement.goals[channel].conversionRate.achievedCurrentMonthUntilToday,
                averageTicketGoal: companyEngagement.goals[channel].averageTicket.goal,
                averageTicketResult: companyEngagement.goals[channel].averageTicket.result,
                averageTicketAchieved: companyEngagement.goals[channel].averageTicket.achieved,
                averageTicketAchievedCurrentMonthUntilToday: companyEngagement.goals[channel].averageTicket.achievedCurrentMonthUntilToday
            };
        });
    }

    const generateCSV = (data: CompanyEngagement[]): string => {
        const csvHeader = [
            'Empresa', 'Email (Empresa)', 'Classificação', 'Pontuação', 'Meta de Receita', 'Resultado de Receita', 'Desvio de Receita', 'Desvio de Receita (Mês Atual até Hoje)',
            'Meta de Sessões', 'Resultado de Sessões', 'Desvio de Sessões', 'Desvio de Sessões (Mês Atual até Hoje)', 
            'Meta de Taxa de Conversão', 'Resultado de Taxa de Conversão', 'Desvio de Taxa de Conversão', 'Desvio de Taxa de Conversão (Mês Atual até Hoje)', 
            'Meta de Ticket Médio', 'Resultado de Ticket Médio', 'Desvio de Ticket Médio', 'Desvio de Ticket Médio (Mês Atual até Hoje)',
            'Meta de Receita (Mídia)', 'Resultado de Receita (Mídia)', 'Desvio de Receita (Mídia)', 'Desvio de Receita (Mídia) (Mês Atual até Hoje)',
            'Meta de Sessões (Mídia)', 'Resultado de Sessões (Mídia)', 'Desvio de Sessões (Mídia)', 'Desvio de Sessões (Mídia) (Mês Atual até Hoje)',
            'Meta de Taxa de Conversão (Mídia)', 'Resultado de Taxa de Conversão (Mídia)', 'Desvio de Taxa de Conversão (Mídia)', 'Desvio de Taxa de Conversão (Mídia) (Mês Atual até Hoje)',
            'Meta de Ticket Médio (Mídia)', 'Resultado de Ticket Médio (Mídia)', 'Desvio de Ticket Médio (Mídia)', 'Desvio de Ticket Médio (Mídia) (Mês Atual até Hoje)',
            'Meta de Receita (Direto)', 'Resultado de Receita (Direto)', 'Desvio de Receita (Direto)', 'Desvio de Receita (Direto) (Mês Atual até Hoje)',
            'Meta de Sessões (Direto)', 'Resultado de Sessões (Direto)', 'Desvio de Sessões (Direto)', 'Desvio de Sessões (Direto) (Mês Atual até Hoje)',
            'Meta de Taxa de Conversão (Direto)', 'Resultado de Taxa de Conversão (Direto)', 'Desvio de Taxa de Conversão (Direto)', 'Desvio de Taxa de Conversão (Direto) (Mês Atual até Hoje)',
            'Meta de Ticket Médio (Direto)', 'Resultado de Ticket Médio (Direto)', 'Desvio de Ticket Médio (Direto)', 'Desvio de Ticket Médio (Direto) (Mês Atual até Hoje)',
            'Meta de Receita (CRM)', 'Resultado de Receita (CRM)', 'Desvio de Receita (CRM)', 'Desvio de Receita (CRM) (Mês Atual até Hoje)',
            'Meta de Sessões (CRM)', 'Resultado de Sessões (CRM)', 'Desvio de Sessões (CRM)', 'Desvio de Sessões (CRM) (Mês Atual até Hoje)',
            'Meta de Taxa de Conversão (CRM)', 'Resultado de Taxa de Conversão (CRM)', 'Desvio de Taxa de Conversão (CRM)', 'Desvio de Taxa de Conversão (CRM) (Mês Atual até Hoje)',
            'Meta de Ticket Médio (CRM)', 'Resultado de Ticket Médio (CRM)', 'Desvio de Ticket Médio (CRM)', 'Desvio de Ticket Médio (CRM) (Mês Atual até Hoje)',
            'Meta de Receita (SEO)', 'Resultado de Receita (SEO)', 'Desvio de Receita (SEO)', 'Desvio de Receita (SEO) (Mês Atual até Hoje)',
            'Meta de Sessões (SEO)', 'Resultado de Sessões (SEO)', 'Desvio de Sessões (SEO)', 'Desvio de Sessões (SEO) (Mês Atual até Hoje)',
            'Meta de Taxa de Conversão (SEO)', 'Resultado de Taxa de Conversão (SEO)', 'Desvio de Taxa de Conversão (SEO)', 'Desvio de Taxa de Conversão (SEO) (Mês Atual até Hoje)',
            'Meta de Ticket Médio (SEO)', 'Resultado de Ticket Médio (SEO)', 'Desvio de Ticket Médio (SEO)' , 'Desvio de Ticket Médio (SEO) (Mês Atual até Hoje)'
        ];
        const csvRows = data.map(row => [
            row.companyName, row.companyOwnerEmail, row.classification, row.totalPoints,
            row.goals.allChannels.revenue.goal, row.goals.allChannels.revenue.result, row.goals.allChannels.revenue.achieved, row.goals.allChannels.revenue.achievedCurrentMonthUntilToday,
            row.goals.allChannels.sessions.goal, row.goals.allChannels.sessions.result, row.goals.allChannels.sessions.achieved, row.goals.allChannels.sessions.achievedCurrentMonthUntilToday,
            row.goals.allChannels.conversionRate.goal, row.goals.allChannels.conversionRate.result, row.goals.allChannels.conversionRate.achieved, row.goals.allChannels.conversionRate.achievedCurrentMonthUntilToday,
            row.goals.allChannels.averageTicket.goal, row.goals.allChannels.averageTicket.result, row.goals.allChannels.averageTicket.achieved, row.goals.allChannels.averageTicket.achievedCurrentMonthUntilToday,
            row.goals.paidMedia.revenue.goal, row.goals.paidMedia.revenue.result, row.goals.paidMedia.revenue.achieved, row.goals.paidMedia.revenue.achievedCurrentMonthUntilToday,
            row.goals.paidMedia.sessions.goal, row.goals.paidMedia.sessions.result, row.goals.paidMedia.sessions.achieved, row.goals.paidMedia.sessions.achievedCurrentMonthUntilToday,
            row.goals.paidMedia.conversionRate.goal, row.goals.paidMedia.conversionRate.result, row.goals.paidMedia.conversionRate.achieved, row.goals.paidMedia.conversionRate.achievedCurrentMonthUntilToday,
            row.goals.paidMedia.averageTicket.goal, row.goals.paidMedia.averageTicket.result, row.goals.paidMedia.averageTicket.achieved, row.goals.paidMedia.averageTicket.achievedCurrentMonthUntilToday,
            row.goals.direct.revenue.goal, row.goals.direct.revenue.result, row.goals.direct.revenue.achieved, row.goals.direct.revenue.achievedCurrentMonthUntilToday,
            row.goals.direct.sessions.goal, row.goals.direct.sessions.result, row.goals.direct.sessions.achieved, row.goals.direct.sessions.achievedCurrentMonthUntilToday,
            row.goals.direct.conversionRate.goal, row.goals.direct.conversionRate.result, row.goals.direct.conversionRate.achieved, row.goals.direct.conversionRate.achievedCurrentMonthUntilToday,
            row.goals.direct.averageTicket.goal, row.goals.direct.averageTicket.result, row.goals.direct.averageTicket.achieved, row.goals.direct.averageTicket.achievedCurrentMonthUntilToday,
            row.goals.crm.revenue.goal, row.goals.crm.revenue.result, row.goals.crm.revenue.achieved, row.goals.crm.revenue.achievedCurrentMonthUntilToday,
            row.goals.crm.sessions.goal, row.goals.crm.sessions.result, row.goals.crm.sessions.achieved, row.goals.crm.sessions.achievedCurrentMonthUntilToday,
            row.goals.crm.conversionRate.goal, row.goals.crm.conversionRate.result, row.goals.crm.conversionRate.achieved, row.goals.crm.conversionRate.achievedCurrentMonthUntilToday,
            row.goals.crm.averageTicket.goal, row.goals.crm.averageTicket.result, row.goals.crm.averageTicket.achieved, row.goals.crm.averageTicket.achievedCurrentMonthUntilToday,
            row.goals.seo.revenue.goal, row.goals.seo.revenue.result, row.goals.seo.revenue.achieved, row.goals.seo.revenue.achievedCurrentMonthUntilToday,
            row.goals.seo.sessions.goal, row.goals.seo.sessions.result, row.goals.seo.sessions.achieved, row.goals.seo.sessions.achievedCurrentMonthUntilToday,
            row.goals.seo.conversionRate.goal, row.goals.seo.conversionRate.result, row.goals.seo.conversionRate.achieved, row.goals.seo.conversionRate.achievedCurrentMonthUntilToday,
            row.goals.seo.averageTicket.goal, row.goals.seo.averageTicket.result, row.goals.seo.averageTicket.achieved, row.goals.seo.averageTicket.achievedCurrentMonthUntilToday
        ]);
        const csvContent = [csvHeader, ...csvRows].map(row => row.join(',')).join('\n');
        return csvContent;
    };
          
    const downloadCSV = (csv: string, filename: string): void => {
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
            
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };
    
    const handleExportCompaniesEngagementToCSV = (): void => {
        setLoadingExportButton(true);

        const rowsToExport = companiesEngagement.filter(company => rows.some(row => row.companyId === company.companyId));

        const csv = generateCSV(rowsToExport);
        downloadCSV(csv, 'engajamento-das-empresas.csv');

        setLoadingExportButton(false);
    };

    const countCompanyByClassification = (classification: string) => {
        return companiesEngagement.filter(company => company.classification === classification).length;
    }

    const calculateCompanyClassificationPercentageByClassification = (classification: string) => {
        return Math.round((countCompanyByClassification(classification) / companiesEngagement.length) * 100);
    }

    useEffect(() => {
        const date = new Date();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        loadCompaniesEngagement(month, year);
    }, [loadCompaniesEngagement]);

    useEffect(() => {
        onChangeSearch(searched)
    }, [searched]);

    const columns = [
        { id: 'actions', label: 'Ações', minWidth: 20 },
        { id: 'companyName', label: 'Empresa', minWidth: 100 },
        { id: 'classification', label: 'Classificação', minWidth: 40 },
        { id: 'totalPoints', label: 'Pontuação', minWidth: 20 },
        { id: 'revenueGoal', label: 'Meta de Receita', minWidth: 100, format: formatValueToMoney },
        { id: 'revenueResult', label: 'Resultado de Receita', minWidth: 100, format: formatValueToMoney },
        { id: 'revenueAchieved', label: 'Desvio de Receita', minWidth: 100, format: formatValueToPercentage},
        { id: 'revenueAchievedCurrentMonthUntilToday', label: 'Desvio de Receita (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage},
        { id: 'sessionsGoal', label: 'Meta de Sessões', minWidth: 100, format: formatValueToNumber },
        { id: 'sessionsResult', label: 'Resultado de Sessões', minWidth: 100, format: formatValueToNumber},
        { id: 'sessionsAchieved', label: 'Desvio de Sessões', minWidth: 100, format: formatValueToPercentage },
        { id: 'sessionsAchievedCurrentMonthUntilToday', label: 'Desvio de Sessões (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage },
        { id: 'conversionRateGoal', label: 'Meta de Taxa de Conversão', minWidth: 100, format: formatValueToPercentage },
        { id: 'conversionRateResult', label: 'Resultado de Taxa de Conversão', minWidth: 100, format: formatValueToPercentage },
        { id: 'conversionRateAchieved', label: 'Desvio de Taxa de Conversão', minWidth: 100, format: formatValueToPercentage },
        { id: 'conversionRateAchievedCurrentMonthUntilToday', label: 'Desvio de Taxa de Conversão (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage },
        { id: 'averageTicketGoal', label: 'Meta de Ticket Médio', minWidth: 100, format: formatValueToMoney},
        { id: 'averageTicketResult', label: 'Resultado de Ticket Médio', minWidth: 100, format: formatValueToMoney},
        { id: 'averageTicketAchieved', label: 'Desvio de Ticket Médio', minWidth: 100, format: formatValueToPercentage},
        { id: 'averageTicketAchievedCurrentMonthUntilToday', label: 'Desvio de Ticket Médio (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage}
    ];

    const EnhancedTableHead = ({order, orderBy, onRequestSort}) => {
        const createSortHandler =
          (property: keyof CompanyEngagementRow) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
      
        return (
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  sortDirection={orderBy === column.id ? order : false}
                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : 'asc'}
                    onClick={createSortHandler(column.id as keyof CompanyEngagementRow)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        );
      }

    return (
        <Container>
            <div className='boxModel'>
                <h2 className='titleConsultCompany'>Engajamento das empresas</h2>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <div className='actionsBar'>
                        <TextField 
                            id="outlined-search" 
                            label="Pesquisar empresa"
                            type="search"
                            value={searched}
                            sx={{ width: '25%', marginTop: '10px'}}
                            onChange={(e) => setSearched(e.target.value)}
                            disabled={loading}
                        />
                        <div className='actionsButtons'>
                            <FormControl sx={{ width: 200, marginTop: '10px', marginLeft: "10px" }}>
                                <InputLabel id="multipleClassificationLabel">Classificação</InputLabel>
                                <Select
                                    labelId="multipleClassificationLabel"
                                    value={selectedClassification}
                                    multiple
                                    label="Classificação"
                                    onChange={handleChangeClassification}
                                    disabled={loading}
                                    >
                                    {CLASSIFCATION_OPTIONS.map((name) => (
                                        <MenuItem key={name} value={name}>
                                        {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ width: 200, marginTop: '10px', marginLeft: "10px" }}>
                                <InputLabel id="channelLabel">Canal</InputLabel>
                                <Select
                                    labelId="channelLabel"
                                    value={selectedChannel}
                                    label="Canal"
                                    onChange={handleChangeChannel}
                                    disabled={loading}
                                    >
                                    {CHANNELS_OPTIONS.map((option) => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='pt-br'>
                                <DatePicker
                                    views={['year', 'month']}
                                    label="Selecione o mês e o ano"
                                    value={selectedDate}
                                    onChange={setSelectedDate}
                                    className='datePickerButton'
                                    disabled={loading}
                                    onAccept={onAcceptDateFilter}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>
                    <div className='actionsBar '>
                        <div className='actionsButtons'>
                            <Button 
                                variant="contained" 
                                className='button' 
                                onClick={_ => setOpenRegisterEngagementRuleModal(true)}
                                disabled={loading}
                            >
                                <Add sx={{ mr: 1 }}/> Cadastrar regra
                            </Button>
                            <Button 
                                variant="contained" 
                                className='button' 
                                onClick={_ => setOpenEditEngagementRuleModal(true)}
                                disabled={loading}
                            >
                                <Edit sx={{ mr: 1 }}/> Editar regras
                            </Button>
                            <Button 
                                variant="contained" 
                                className='button' 
                                color="secondary"
                                onClick={handleExportCompaniesEngagementToCSV}
                                disabled={loading || loadingExportButton || rows.length === 0}
                            >
                                <InsertDriveFile sx={{ mr: 1 }}/> Exportar
                            </Button>
                            <Button 
                                variant="contained" 
                                className='button'
                                color="success"
                                onClick={_ => setOpenMatrixModal(true)}
                                disabled={loading || rows.length === 0}
                            >
                                <BorderAll sx={{ mr: 1 }}/> Matriz
                            </Button>
                        </div>
                    </div>
                    <TableContainer sx={{ maxHeight: "auto" }}>
                        <Table stickyHeader aria-label="sticky table">
                            <EnhancedTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                            />
                            <TableBody>
                                {!loading && sortedRows.length > 0 &&  sortedRows
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                {columns.map((column) => {

                                                    if (column.id === 'actions') {
                                                        return (
                                                            <TableCell key={column.id} sx={{ display: 'flex', justifyContent: 'space-around'}}>
                                                                <Button 
                                                                    variant="outlined" 
                                                                    color="success"
                                                                    onClick={() => handleOpenRegisterCompanyEngagementResultModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <PostAdd/>
                                                                </Button>
                                                                <Button 
                                                                    variant="outlined" 
                                                                    color="secondary"
                                                                    onClick={() => handleOpenCompanyEngagementResultModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <Checklist/>
                                                                </Button>
                                                                <Button 
                                                                    variant="outlined" 
                                                                    color="info"
                                                                    onClick={() => handleOpenCompanyEngagementGoalsByChannelModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <AutoGraph/>
                                                                </Button>
                                                            </TableCell>
                                                        );
                                                    }

                                                    if ([
                                                        'revenueAchieved', 'sessionsAchieved', 'conversionRateAchieved', 'averageTicketAchieved',
                                                        'revenueAchievedCurrentMonthUntilToday', 'sessionsAchievedCurrentMonthUntilToday',
                                                        'conversionRateAchievedCurrentMonthUntilToday', 'averageTicketAchievedCurrentMonthUntilToday'
                                                    ].includes(column.id)) {
                                                        return (
                                                            <TableCell key={column.id} style={{ backgroundColor: defineCellBackgroundColor(row[column.id]) }}>
                                                                {column.format ? column.format(row[column.id]) : row[column.id]}
                                                            </TableCell>
                                                        );
                                                    }
                                                    
                                                    return (
                                                        <TableCell key={column.id}>
                                                            {column.format ? column.format(row[column.id]) : row[column.id]}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                                {!loading && sortedRows.length == 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            Nenhuma empresa encontrada.
                                        </TableCell>
                                    </TableRow>
                                )}
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
            </div>
            {selectedCompanyEngagement && selectedDate && openCompanyEngagementResultModal && (
                <CompanyEngagementResult
                    handleCloseModal={handleCloseModal}
                    selectedCompanyEngagement={selectedCompanyEngagement}
                    openModal={openCompanyEngagementResultModal}
                    setActiveFullScreenLoading={setActiveFullScreenLoading}
                    month={selectedDate.month() + 1}
                    year={selectedDate.year()}
                    loadCompaniesEngagement={loadCompaniesEngagement}
                />
            )}
            {selectedCompanyEngagement && selectedDate && openRegisterCompanyEngagementResultModal && (
                <RegisterCompanyEngagementResult
                    handleCloseModal={handleCloseModal}
                    selectedCompanyEngagement={selectedCompanyEngagement}
                    openModal={openRegisterCompanyEngagementResultModal}
                    setActiveFullScreenLoading={setActiveFullScreenLoading}
                    month={selectedDate.month() + 1}
                    year={selectedDate.year()}
                    loadCompaniesEngagement={loadCompaniesEngagement}
                />
            )}
            {selectedCompanyEngagement && openCompanyEngagementGoalsByChannelModal && (
                <CompanyEngagementGoalsByChannel
                    handleCloseModal={handleCloseModal}
                    selectedCompanyEngagement={companiesEngagement.find(company => company.companyId === selectedCompanyEngagement.companyId)}
                    openModal={openCompanyEngagementGoalsByChannelModal}
                    formatValueToMoney={formatValueToMoney}
                    formatValueToNumber={formatValueToNumber}
                    formatValueToPercentage={formatValueToPercentage}
                    defineCellBackgroundColor={defineCellBackgroundColor}
                />
            )}
            {openRegisterEngagementRuleModal && (
                <RegisterEngagementRule
                    openModal={openRegisterEngagementRuleModal}
                    handleCloseModal={handleCloseModal}
                    setActiveFullScreenLoading={setActiveFullScreenLoading}
                />
            )}
            {openEditEngagementRuleModal && (
                <EditEngagementRule
                    openModal={openEditEngagementRuleModal}
                    handleCloseModal={handleCloseModal}
                    setActiveFullScreenLoading={setActiveFullScreenLoading}
                />
            )}
            {companiesEngagement.length > 0 && openMatrixModal && (
                <Matrix
                    openModal={openMatrixModal}
                    handleClose={handleCloseModal} 
                    incipient={{
                        name: 'Q1 - INCIPIENT',
                        amount: countCompanyByClassification('INCIPIENT'),
                        percent: calculateCompanyClassificationPercentageByClassification('INCIPIENT')
                    }}
                    inefetivo={{
                        name: 'Q2 - INEFETIVO',
                        amount: countCompanyByClassification('INEFETIVO'),
                        percent: calculateCompanyClassificationPercentageByClassification('INEFETIVO')
                    }}
                    highPotential={{
                        name: 'Q3 - HIGH POTENTIAL',
                        amount: countCompanyByClassification('HIGH POTENTIAL'),
                        percent: calculateCompanyClassificationPercentageByClassification('HIGH POTENTIAL')
                    }}
                    highPerformance={{
                        name: 'Q4 - HIGH PERFORMER',
                        amount: countCompanyByClassification('HIGH PERFORMER'),
                        percent: calculateCompanyClassificationPercentageByClassification('HIGH PERFORMER')
                    }}
                    totalPossiblePoints={companiesEngagement[0].maxPossiblePoints}
                />
            )}
            <FullScreenLoading open={activeFullScreenLoading} />
        </Container>
    )
}