import React from 'react';

import './index.css';

import { 
  Dialog, 
  DialogContent,
  DialogTitle,
  DialogActions,
  Button
} from '@mui/material';

export const Matrix = ({ 
  openModal, handleClose, incipient, inefetivo, 
  highPotential, highPerformance, totalPossiblePoints
}) => {
  return (
    <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="dialogTitleEngagementAndGoalsMatrix"
        aria-describedby="dialogDescriptionEngagementAndGoalsMatrix"
        maxWidth="lg"
      >
        <DialogContent
            id="dialogDescriptionEngagementAndGoalsMatrix"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
        > 
          <div className="matrixContainer">
            <div className="matrixYAxis">
              <div className="yAxisItemTop">maior que meta</div>
              <div className="yAxisItemMiddle">
                <div className="yAxisItemMiddleTitle">
                  <p>Atingimento da meta</p>
                  <p>Receita</p>
                </div>
                <div className="yAxisItemMiddleValue">100%</div>
              </div>
              <div className="yAxisItemBottom">menor que meta</div>
            </div>
            <div className="matrix">
              <div className='top'>
                <div className="topLeft quadrant">
                  <div className="ratingName">{highPotential.name}</div>
                  <div className="ratingDetails">
                    <div className="amount">{highPotential.amount}</div>
                    <div className="percent">({highPotential.percent}%)</div>
                  </div>
                </div>
                <div className="topRight quadrant">
                  <div className="ratingName">{highPerformance.name}</div>
                  <div className="ratingDetails">
                    <div className="amount">{highPerformance.amount}</div>
                    <div className="percent">({highPerformance.percent}%)</div>
                  </div>
                </div>
              </div>
              <div className='bottom'>
                <div className="bottomLeft quadrant">
                  <div className="ratingName">{incipient.name}</div>
                  <div className="ratingDetails">
                    <div className="amount">{incipient.amount}</div>
                    <div className="percent">({incipient.percent}%)</div>
                  </div>
                </div>
                <div className="bottomRight quadrant">
                  <div className="ratingName">{inefetivo.name}</div>
                  <div className="ratingDetails">
                    <div className="amount">{inefetivo.amount}</div>
                    <div className="percent">({inefetivo.percent}%)</div>
                  </div>
                </div>
              </div>
              <div className="matrixXAxis">
                <div className="xAxisItemTop">0 pontos</div>
                <div className="xAxisItemMiddle">
                  {Math.round(totalPossiblePoints / 2)} pontos
                </div>
                <div className="xAxisItemBottom">{totalPossiblePoints} pontos</div>
                </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
            <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
    </Dialog>
  );
};
