class CompanyEngagementGoalMetric {
    public result: number = 0
    public goal: number = 0
    public achieved: number = 0
    public achievedCurrentMonthUntilToday: number = 0
}

class CompanyEngagementGoalsMetrics {
    public revenue: CompanyEngagementGoalMetric = new CompanyEngagementGoalMetric()
    public sessions: CompanyEngagementGoalMetric = new CompanyEngagementGoalMetric()
    public transactions: CompanyEngagementGoalMetric = new CompanyEngagementGoalMetric()
    public conversionRate: CompanyEngagementGoalMetric = new CompanyEngagementGoalMetric()
    public averageTicket: CompanyEngagementGoalMetric = new CompanyEngagementGoalMetric()
}

class CompanyEngagementGoals {
    public allChannels: CompanyEngagementGoalsMetrics = new CompanyEngagementGoalsMetrics()
    public paidMedia: CompanyEngagementGoalsMetrics = new CompanyEngagementGoalsMetrics()
    public direct: CompanyEngagementGoalsMetrics = new CompanyEngagementGoalsMetrics()
    public crm: CompanyEngagementGoalsMetrics = new CompanyEngagementGoalsMetrics()
    public seo: CompanyEngagementGoalsMetrics = new CompanyEngagementGoalsMetrics()
}

export class CompanyEngagement {
    public companyId: string = ''
    public companyName: string = ''
    public companyOwnerEmail: string = ''
    public classification: string = ''
    public maxPossiblePoints: number = 0
    public totalPoints: number = 0
    public goals: CompanyEngagementGoals = new CompanyEngagementGoals()
}

export class RegisterEngagementRule {
    public name: string = ''
    public type: string = ''
    public points: number = 0
}

export class EngagementRule extends RegisterEngagementRule {
    public id: string = ''
    public active: boolean = false
}

export class RegisterCompanyEngagementResult {
    public engagementRuleId: string = ''
    public month: number = 0
    public year: number = 0
    public booleanResultValue: boolean = false
    public numberReachValue: number = 0
    public numberResultValue: number = 0
}

export class CompanyEngagementResult extends RegisterCompanyEngagementResult {
    public id: string = ''
    public points: number = 0
    public modifiable: boolean = false
    public engagementRuleName: string = ''
    public engagementRuleType: string = ''
}