import React, { useState } from 'react';

import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField 
} from '@mui/material';

import { RegisterPartnerEntity } from '../../../models';

export const EditPartner = ({
    handleCloseModal,
    handleEditPartner,
    isValidPartnerData,
    selectedPartner,
    openModal
}) => {
  const [partnerName, setPartnerName] = useState(selectedPartner.name);
  const [partnerImageUrl, setPartnerImageUrl] = useState(selectedPartner.imageUrl);
  const [partnerDescription, setPartnerDescription] = useState(selectedPartner.description);
  const [partnerExpertise, setPartnerExpertise] = useState(selectedPartner.expertise);
  const [partnerCommercialBenefit, setPartnerCommercialBenefit] = useState(selectedPartner.commercialBenefit);
  const [partnerRedirect, setPartnerRedirect] = useState(selectedPartner.redirect);

  const handleSubmit = (event) => {
    event.preventDefault();

    const partnerData = new RegisterPartnerEntity({
      name: partnerName,
      imageUrl: partnerImageUrl,
      description: partnerDescription,
      expertise: partnerExpertise,
      commercialBenefit: partnerCommercialBenefit,
      redirect: partnerRedirect
    })

    if(isValidPartnerData(partnerData)) {
      handleEditPartner(selectedPartner.id, partnerData);
    }
  }

  return (
      <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="dialogTitleEditPartner"
          aria-describedby="dialogDescriptionEditPartner"
        >
      <DialogTitle id="dialogTitleEditPartner">
        {"Editar parceiro"}
      </DialogTitle>
      <form className="formEditPartner" onSubmit={handleSubmit} >
        <DialogContent
          id="dialogDescriptionEditPartner"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="partnerName"
                  label="Nome do parceiro"
                  type='text'
                  variant="outlined" 
                  value={partnerName}
                  onChange={(event) => setPartnerName(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="partnerImageUrl"
                  label="URL da imagem do parceiro"
                  type='text'
                  variant="outlined" 
                  value={partnerImageUrl}
                  onChange={(event) => setPartnerImageUrl(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="partnerDescription"
                  label="Descrição do parceiro"
                  type='text'
                  variant="outlined" 
                  value={partnerDescription}
                  onChange={(event) => setPartnerDescription(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="partnerExpertise"
                  label="Expertise do parceiro"
                  type='text'
                  variant="outlined" 
                  value={partnerExpertise}
                  onChange={(event) => setPartnerExpertise(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="partnerCommercialBenefit"
                  label="Benefício comercial do parceiro"
                  type='text'
                  variant="outlined" 
                  value={partnerCommercialBenefit}
                  onChange={(event) => setPartnerCommercialBenefit(event.target.value)}
              />
          </FormControl>
          <FormControl variant="outlined" sx={{ m: 1, minWidth: 400 }}>
              <TextField 
                  required
                  id="partnerRedirect"
                  label="URL de redirecionamento do parceiro"
                  type='text'
                  variant="outlined" 
                  value={partnerRedirect}
                  onChange={(event) => setPartnerRedirect(event.target.value)}
              />
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button
            type='submit' 
            variant="contained" 
            autoFocus
          >
            Confirmar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}