import React, { useState, useEffect, useCallback } from 'react';

import { 
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow
} from '@mui/material';

import { ThumbUp, ThumbDown, Delete } from '@mui/icons-material';

import { getCompanyEngagementResult, deleteCompanyEngagementResult } from '../../../adapters';

import { CompanyEngagementResult as CompanyEngagementResultInterface } from '../../../models';

import { toast } from 'react-toastify';

interface CompanyEngagementResultRow extends CompanyEngagementResultInterface {
  index: number;
}

export const CompanyEngagementResult = ({
    handleCloseModal,
    selectedCompanyEngagement,
    openModal,
    setActiveFullScreenLoading,
    month,
    year,
    loadCompaniesEngagement
}) =>  {
  const [companyEngagementResults, setCompanyEngagementResults] = useState<CompanyEngagementResultInterface[]>([]);
  const [loading, setLoading] = useState(true);

  const getCompanyEngagementResultList = useCallback(async () => {
    setLoading(true);
    setActiveFullScreenLoading(true);

    try {
      const companyEngagementResultsResponse = await getCompanyEngagementResult(month, year, selectedCompanyEngagement.companyId);
      setCompanyEngagementResults(companyEngagementResultsResponse);
    } catch (error) {
      toast.error('Não foi possível carregar os resultados de engajamento da empresa. Tente novamente mais tarde.');
      handleClose();
    }

    setLoading(false);
    setActiveFullScreenLoading(false);
  }, []);

  const handleDeleteCompanyEngagementResultById = async (companyEngagementId: string) => {
    setActiveFullScreenLoading(true);

    try {
      await deleteCompanyEngagementResult(companyEngagementId);
      
      toast.success(`Resultado de engajamento da empresa excluído com sucesso.`);

      handleClose();
      
      loadCompaniesEngagement(month, year);
    } catch (error) {
      toast.error(`Não foi possível excluir o resultado de engajamento da empresa. Tente novamente mais tarde.`);
    }

    setActiveFullScreenLoading(false);
  }

  const handleClose = () => {
    setCompanyEngagementResults([]);
    setLoading(true);
    handleCloseModal();
  }

  useEffect(() => {
    getCompanyEngagementResultList();
  }, []);

  const columns = [
    { 
      id: 'engagementRuleName', 
      label: 'Regra', 
      minWidth: 120
    },
    {
      id: 'engagementRuleType',
      label: 'Tipo de regra',
      minWidth: 40,
      format : (value: string) => value === 'number' ? 'Numérico' : 'Verdadeiro/Falso'
    },
    {
      id: 'points',
      label: 'Pontos',
      minWidth: 30
    },
    {
      id: 'numberReachValue',
      label: 'Resultado esperado (Numérico)',
      minWidth: 20
    },
    {
      id: 'numberResultValue',
      label: 'Resultado obtido (Numérico)',
      minWidth: 20
    },
    {
      id: 'booleanResultValue',
      label: 'Resultado obtido (Verdadeiro/Falso)',
      minWidth: 30
    },
    {
      id: 'actions',
      label: 'Ações',
      minWidth: 30
    }
  ];

  const renderRow = (row: CompanyEngagementResultRow) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={row.index}>
          {columns.map((column) => {
              if (column.id === 'booleanResultValue' && row.engagementRuleType === 'boolean') {
                  return (
                      <TableCell key={column.id}>
                          {row[column.id] ? <ThumbUp style={{color: "green"}} /> : <ThumbDown style={{color: "red"}} />}
                      </TableCell>
                  );
              }

              if (["numberReachValue", "numberResultValue"].includes(column.id) && row.engagementRuleType === 'number') {
                return (
                  <TableCell key={column.id}>
                    {row[column.id]}
                  </TableCell>
                );
              }

              if (["numberReachValue", "numberResultValue", "booleanResultValue"].includes(column.id)) {
                return (
                  <TableCell key={column.id}>
                    -
                  </TableCell>
                );
              }

              if (column.id === 'actions') {
                return (
                    <TableCell key={column.id}>
                        <Button
                            disabled={row.modifiable === false} 
                            variant="outlined" 
                            color="error"
                            onClick={() => handleDeleteCompanyEngagementResultById(row.id)}
                            sx={{ m: 1 }}
                        >
                            <Delete/>
                        </Button>
                    </TableCell>
                );
             }
              
              return (
                  <TableCell key={column.id}>
                      {column.format ? column.format(row[column.id]) : row[column.id]}
                  </TableCell>
              );
          })}
      </TableRow>
    );
  }

  return ( 
    <>
      <Dialog
        open={openModal && !loading}
        onClose={handleClose}
        aria-labelledby="dialogTitleCompanyEngagementResult"
        aria-describedby="dialogDescriptionCompanyEngagementResult"
        maxWidth="lg"
      >
        <DialogTitle id="dialogTitleCompanyEngagementResult">
          {"Resultados de engajamento da empresa"}
        </DialogTitle>
        <DialogContent
          id="dialogDescriptionCompanyEn"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="companyEngagementResultList">
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                          {columns.map((column) => (
                              <TableCell
                                  key={column.id}
                                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                              >
                                  {column.label}
                              </TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {companyEngagementResults.length > 0 && companyEngagementResults.map((result, index) => {
                    return renderRow({
                      index, ...result
                    })
                  })}
                  {companyEngagementResults.length === 0 && (
                      <TableRow>
                          <TableCell colSpan={6} align="center">
                              Nenhum resultado de engajamento encontrado
                          </TableCell>
                      </TableRow>
                  )}
                  </TableBody>
              </Table>
            </TableContainer>
        </div>  
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}