import React from 'react';

import { 
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow
} from '@mui/material';

import { CompanyEngagement } from '../../../models';

interface CompanyEngagementGoalsByChannelRow {
  index: number;
  channel: string;
  revenueGoal: number;
  revenueResult: number;
  revenueAchieved: number;
  revenueAchievedCurrentMonthUntilToday: number;
  sessionsGoal: number;
  sessionsResult: number;
  sessionsAchieved: number;
  sessionsAchievedCurrentMonthUntilToday: number;
  conversionRateGoal: number;
  conversionRateResult: number;
  conversionRateAchieved: number;
  conversionRateAchievedCurrentMonthUntilToday: number;
  averageTicketGoal: number;
  averageTicketResult: number;
  averageTicketAchieved: number;
  averageTicketAchievedCurrentMonthUntilToday: number;
}

export const CompanyEngagementGoalsByChannel = ({
    handleCloseModal,
    selectedCompanyEngagement,
    openModal,
    formatValueToMoney,
    formatValueToNumber,
    formatValueToPercentage,
    defineCellBackgroundColor
}) =>  {
  const getFormattedRowData = (companyEngagement: CompanyEngagement) => {
    return Object.keys(companyEngagement.goals).map((key, index) => {
      if (key !== 'allChannels') {
        return {
            index: index,
            channel: key,
            revenueGoal: companyEngagement.goals[key].revenue.goal,
            revenueResult: companyEngagement.goals[key].revenue.result,
            revenueAchieved: companyEngagement.goals[key].revenue.achieved,
            revenueAchievedCurrentMonthUntilToday: companyEngagement.goals[key].revenue.achievedCurrentMonthUntilToday,
            sessionsGoal: companyEngagement.goals[key].sessions.goal,
            sessionsResult: companyEngagement.goals[key].sessions.result,
            sessionsAchieved: companyEngagement.goals[key].sessions.achieved,
            sessionsAchievedCurrentMonthUntilToday: companyEngagement.goals[key].sessions.achievedCurrentMonthUntilToday,
            conversionRateGoal: companyEngagement.goals[key].conversionRate.goal,
            conversionRateResult: companyEngagement.goals[key].conversionRate.result,
            conversionRateAchieved: companyEngagement.goals[key].conversionRate.achieved,
            conversionRateAchievedCurrentMonthUntilToday: companyEngagement.goals[key].conversionRate.achievedCurrentMonthUntilToday,
            averageTicketGoal: companyEngagement.goals[key].averageTicket.goal,
            averageTicketResult: companyEngagement.goals[key].averageTicket.result,
            averageTicketAchieved: companyEngagement.goals[key].averageTicket.achieved,
            averageTicketAchievedCurrentMonthUntilToday: companyEngagement.goals[key].averageTicket.achievedCurrentMonthUntilToday
        };
      }
    }) as CompanyEngagementGoalsByChannelRow[];
  };

  const handleClose = () => {
    handleCloseModal();
  }

  const getChannelLabel = (channel: string) => {
    switch (channel) {
      case 'paidMedia':
        return 'Mídia';
      case 'direct':
        return 'Direto';
      case 'crm':
        return 'CRM';
      case 'seo':
        return 'SEO';
      default:
        return '';
    }
  }

  const columns = [
    { id: 'channel', label: 'Canal', minWidth: 100, format: getChannelLabel},
    { id: 'revenueGoal', label: 'Meta de Receita', minWidth: 100, format: formatValueToMoney },
    { id: 'revenueResult', label: 'Resultado de Receita', minWidth: 100, format: formatValueToMoney },
    { id: 'revenueAchieved', label: 'Desvio de Receita', minWidth: 100, format: formatValueToPercentage},
    { id: 'revenueAchievedCurrentMonthUntilToday', label: 'Desvio de Receita (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage},
    { id: 'sessionsGoal', label: 'Meta de Sessões', minWidth: 100, format: formatValueToNumber },
    { id: 'sessionsResult', label: 'Resultado de Sessões', minWidth: 100, format: formatValueToNumber},
    { id: 'sessionsAchieved', label: 'Desvio de Sessões', minWidth: 100, format: formatValueToPercentage },
    { id: 'sessionsAchievedCurrentMonthUntilToday', label: 'Desvio de Sessões (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage },
    { id: 'conversionRateGoal', label: 'Meta de Taxa de Conversão', minWidth: 100, format: formatValueToPercentage },
    { id: 'conversionRateResult', label: 'Resultado de Taxa de Conversão', minWidth: 100, format: formatValueToPercentage },
    { id: 'conversionRateAchieved', label: 'Desvio de Taxa de Conversão', minWidth: 100, format: formatValueToPercentage },
    { id: 'conversionRateAchievedCurrentMonthUntilToday', label: 'Desvio de Taxa de Conversão (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage },
    { id: 'averageTicketGoal', label: 'Meta de Ticket Médio', minWidth: 100, format: formatValueToMoney},
    { id: 'averageTicketResult', label: 'Resultado de Ticket Médio', minWidth: 100, format: formatValueToMoney},
    { id: 'averageTicketAchieved', label: 'Desvio de Ticket Médio', minWidth: 100, format: formatValueToPercentage},
    { id: 'averageTicketAchievedCurrentMonthUntilToday', label: 'Desvio de Ticket Médio (Mês Atual até Hoje)', minWidth: 120, format: formatValueToPercentage}
  ];

  const renderRow = (row: CompanyEngagementGoalsByChannelRow) => {
    if (!row) return null;

    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={row.index}>
          {columns.map((column) => {
            if ([
                'revenueAchieved', 'sessionsAchieved', 'conversionRateAchieved', 'averageTicketAchieved',
                'revenueAchievedCurrentMonthUntilToday', 'sessionsAchievedCurrentMonthUntilToday', 
                'conversionRateAchievedCurrentMonthUntilToday', 'averageTicketAchievedCurrentMonthUntilToday'
            ].includes(column.id)) {
                return (
                    <TableCell key={column.id} style={{ backgroundColor: defineCellBackgroundColor(row[column.id]) }}>
                        {column.format ? column.format(row[column.id]) : row[column.id]}
                    </TableCell>
                );
            }
            
            return (
                <TableCell key={column.id}>
                    {column.format ? column.format(row[column.id]) : row[column.id]}
                </TableCell>
            );
          })}
      </TableRow>
    );
  }

  return ( 
    <>
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="dialogTitleCompanyEngagementGoalsByChannel"
        aria-describedby="dialogDescriptionCompanyEngagementGoalsByChannel"
        maxWidth="lg"
      >
        <DialogTitle id="dialogTitleCompanyEngagementGoalsByChannel">
          {"Metas e resultados da empresa por canal"}
        </DialogTitle>
        <DialogContent
          id="dialogDescriptionCompanyEngagementGoalsByChannel"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="companyEngagementResultList">
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                          {columns.map((column) => (
                              <TableCell
                                  key={column.id}
                                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                              >
                                  {column.label}
                              </TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {getFormattedRowData(selectedCompanyEngagement).map((row) => {
                    return renderRow(row)
                  })}
                  </TableBody>
              </Table>
            </TableContainer>
        </div>  
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleClose}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}