import React, { useContext, useEffect, useState} from 'react';

import { useNavigate } from "react-router-dom";

import { Button, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';

import { AuthContext } from '../../contexts';

import { UserRoleType, RegisterUserEntity } from '../../models';

import { Container, FullScreenLoading } from "../../components";

import { toast } from 'react-toastify';

import { registerUser } from '../../adapters';

import './index.css';

export const RegisterUser = () => {
    const navigate = useNavigate();

    const { user } = useContext(AuthContext);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState(UserRoleType.USER);
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);

    useEffect(() => { 
        if (!user) return;
        
        if (user.role !== UserRoleType.ADMIN) {
            navigate('/');
        }
    }, [user]);

    const handleSubmit = async (event) => { 
        event.preventDefault();

        setActiveFullScreenLoading(true);

        try {
            const data = new RegisterUserEntity({
                name,
                email,
                password,
                role
            });

            await registerUser(data);

            toast.success('Usuário cadastrado com sucesso!');

            navigate('/');
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    };

    return (
        <Container>
            <form className="boxModel formRegisterUser" onSubmit={handleSubmit} >
                <h2 className='titleRegisterUser'>Cadastro de usuário</h2>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="name" 
                        label="Nome"
                        type='text'
                        variant="outlined" 
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="email" 
                        label="Email"
                        type='email'
                        variant="outlined" 
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="password" 
                        label="Senha" 
                        type='password'
                        variant="outlined" 
                        value={password}
                        onChange={(event) => setPassword(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="selectRoleLabel">Permissão</InputLabel>
                    <Select
                        required
                        labelId="selectRoleLabel"
                        id="selectRole"
                        value={role}
                        label="Permissão"
                        onChange={event => setRole(UserRoleType[event.target.value])}
                    >
                        <MenuItem value={UserRoleType.ADMIN}>Administrador</MenuItem>
                        <MenuItem value={UserRoleType.USER}>Usuário</MenuItem>
                    </Select>
                </FormControl>
                <div className="actions">
                    <Button variant="contained" type="submit">Cadastrar</Button>
                </div> 
            </form>
            <FullScreenLoading open={activeFullScreenLoading} />
        </Container>
    )
}