import React, { useState } from 'react';

import { 
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow
} from '@mui/material';

import { AddConnector } from './AddConnector';
import { ConnectorConfig, ConnectorConfigDictionary } from '../../../models';
import { ShowConnectorInfo } from './ShowConnectorInfo';

export const CompanyConnector = ({
    handleCloseModal,
    selectedCompany,
    openModal,
    loadCompanies,
    setActiveFullScreenLoading
}) =>  {
  const [openAddConnectorModal, setOpenAddConnectorModal] = useState(false);
  const [openShowConnectorInfoModal, setOpenShowConnectorInfoModal] = useState(false);
  const [seletecConnectorInfo, setSeletecConnectorInfo] = useState({});
  const [seletecConnectorConfig, setSeletecConnectorConfig] = useState<ConnectorConfig | null>(null);
  const [hasConnectorsEnabled, _] = useState(ConnectorConfigDictionary.hasConnectorsEnabled(selectedCompany.settings))

  const handleCloseAllModal = () => {
    setOpenAddConnectorModal(false);
    handleCloseShowConnectorInfo();
    handleCloseModal();
  }

  const handleShowConnectorInfo = (key: string, connectorInfo) => {
    setSeletecConnectorInfo(connectorInfo);
    setSeletecConnectorConfig(ConnectorConfigDictionary.getConfigByKey(key));
    setOpenShowConnectorInfoModal(true);
  }

  const handleCloseShowConnectorInfo = () => {
    setOpenShowConnectorInfoModal(false);
    setSeletecConnectorInfo({});
    setSeletecConnectorConfig(null);
  }

  const columns = [
    { id: 'displayName', label: 'Conexão', minWidth: 100 },
    {
      id: 'actions',
      label: 'Ações',
      minWidth: 100
    }
  ];

  return ( 
    <>
      <Dialog
        open={openModal}
        onClose={handleCloseAllModal}
        aria-labelledby="dialogTitleCompanyConnector"
        aria-describedby="dialogDescriptionCompanyConnector"
      >
        <DialogTitle id="dialogTitleCompanyConnector">
          {"Configuração de fonte de dados"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpenAddConnectorModal(true)}>Adicionar conexão</Button>
        </DialogActions>
        <DialogContent
          id="dialogDescriptionCompanyConnector"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="connectorList">
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                          {columns.map((column) => (
                              <TableCell
                                  key={column.id}
                                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                              >
                                  {column.label}
                              </TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {selectedCompany.settings && Object.keys(selectedCompany.settings).map((key, index) => {
                    const connectorConfig = ConnectorConfigDictionary.getConfigByKey(key);

                    if (!connectorConfig || !selectedCompany.settings[key]) {
                        return
                    }
                    
                    return (
                        <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                            {columns.map((column) => {
                                if (column.id === 'actions') {
                                    return (
                                        <TableCell key={column.id} sx={{ display: 'flex', justifyContent: 'space-around'}}>
                                            <Button 
                                                variant="outlined" 
                                                color="success"
                                                onClick={() => handleShowConnectorInfo(key, selectedCompany.settings[key])}
                                            >
                                                Visualizar
                                            </Button>
                                        </TableCell>
                                    );
                                }
                                
                                if (!connectorConfig) {
                                    return
                                }

                                return (
                                    <TableCell key={index}>
                                        {connectorConfig[column.id]}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                  );
                  })
                      }
                      {!hasConnectorsEnabled && (
                          <TableRow>
                              <TableCell colSpan={6} align="center">
                                  Nenhuma conexão configurada
                              </TableCell>
                          </TableRow>
                      )}
                  </TableBody>
              </Table>
            </TableContainer>
        </div>  
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleCloseAllModal}>Fechar</Button>
        </DialogActions>
      </Dialog>
      {selectedCompany && <AddConnector 
        handleCloseAllModal={handleCloseAllModal}
        handleCloseModal={() => setOpenAddConnectorModal(false)}
        openModal={openAddConnectorModal}
        selelectedCompanyId={selectedCompany.companyId}
        selectedCompany={selectedCompany}
        loadCompanies={loadCompanies}
        setActiveFullScreenLoading={setActiveFullScreenLoading}
      />}
      {selectedCompany && <ShowConnectorInfo
        handleCloseModal={handleCloseShowConnectorInfo}
        openModal={openShowConnectorInfoModal}
        connectorConfig={seletecConnectorConfig}
        connectorInfo={seletecConnectorInfo}
      />}
    </>
  );
}