import{ HttpStatusCode } from 'axios';

import { sendRequest } from './api';

import { RegisterPartnerEntity, PartnerEntity } from '../models';

export const getPartners = async (): Promise<PartnerEntity[]> => {
    const response = await sendRequest("GET", 'partner/');

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error();
    }

    return response.data;
}

export const updatePartner = async (id: string, data: RegisterPartnerEntity): Promise<void> => {
    const response = await sendRequest(
        "PUT", 
        `partner/${id}`, 
        data
    );

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao tentar atualizar o parceiro. Tente novamente mais tarde.");
    }
}

export const deletePartner = async (id: string): Promise<void> => {
    const response = await sendRequest("DELETE", `partner/${id}`);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao tentar deletar o parceiro. Tente novamente mais tarde.");
    }
}

export const registerPartner = async (data: RegisterPartnerEntity): Promise<void> => {
    const response = await sendRequest("POST", 'partner/register', data);

    if (response.status === HttpStatusCode.BadRequest) {
        const message = response.data.errors.map((error_message: string) => error_message).join("\n");

        throw new Error(message);
    }

    if (response.status !== HttpStatusCode.Created && response.status !== HttpStatusCode.Ok) {
        throw new Error("Ocorreu um erro ao tentar cadastrar o parceiro. Tente novamente mais tarde.");
    }
}