import React, { useState, useCallback, useEffect, useContext } from 'react';

import dayjs from 'dayjs';

import { AuthContext } from '../../contexts';

import { 
    Button, 
    CircularProgress, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TablePagination, 
    TableRow,
    TextField
} from '@mui/material';

import { Delete, Edit, AccountTree, Psychology } from '@mui/icons-material';

import { Container, FullScreenLoading } from "../../components";

import { DeleteCompany } from './DeleteCompany';

import { EditCompany } from './EditCompany';

import { CompanyConnector } from './CompanyConnector';

import { CompanyFeature } from './CompanyFeature';

import { CompanyEntity, UserRoleType } from '../../models';

import { getCompanies, deleteCompany, updateCompany } from '../../adapters';

import { toast } from 'react-toastify';

import './index.css';

export const ConsultCompany = () => {
    const { user } = useContext(AuthContext);

    const PAGE_INITIAL_STATE = 0;
    const ROWS_PER_PAGE_INITIAL_STATE = 10;
    const [page, setPage] = useState(PAGE_INITIAL_STATE);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_INITIAL_STATE);
    const [loading, setLoading] = useState(true);
    const [companies, setCompanies] = useState<CompanyEntity[]>([]);
    const [rows, setRows] = useState<CompanyEntity[]>([]);
    const [searched, setSearched] = useState<string>('');
    const [disableDeleteButton, setDisableDeleteButton] = useState<boolean>(true);
    const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
    const [openEditModal, setOpenEditModal] = useState<boolean>(false);
    const [openCompanyConnectorModal, setOpenCompanyConnectorModal] = useState<boolean>(false);
    const [openCompanyFeatureModal, setOpenCompanyFeatureModal] = useState<boolean>(false);
    const [selectedCompany, setSelectedCompany] = useState<CompanyEntity| null>(null);
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);

    const loadCompanies = useCallback(async () => {
        setLoading(true);

        try {
            const companies = await getCompanies();
            
            setPage(PAGE_INITIAL_STATE);
            setRowsPerPage(ROWS_PER_PAGE_INITIAL_STATE);
            setSearched('');

            setCompanies(companies);
            setRows(companies);
        } catch (error) {
            toast.error('Ocorreu um erro ao carregar as empresas. Tente novamente mais tarde.');
        }

        setLoading(false);
    }, []);

    const handleDeleteCompany = async (id: string) => {
        setActiveFullScreenLoading(true);

        try {
            await deleteCompany(id);

            toast.success('Empresa excluída com sucesso.');

            handleCloseModal()

            loadCompanies();
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    }

    const handleEditCompany = async (id: string, newCompanyName: string, newCompanyOwnerEmail: string) => {
        setActiveFullScreenLoading(true)

        try {
            await updateCompany(id, newCompanyName, newCompanyOwnerEmail);

            toast.success('Empresa atualizada com sucesso.');
            
            handleCloseModal()

            loadCompanies();
        } catch (error: any) {
            toast.error(error.message);
        }

        setActiveFullScreenLoading(false);
    }

    const handleOpenDeleteModal = (company: CompanyEntity) => {
        setSelectedCompany(company);
        setOpenDeleteModal(true);
    }

    const handleOpenEditModal = (company: CompanyEntity) => {
        setSelectedCompany(company);
        setOpenEditModal(true);
    }

    const handleOpenCompanyConnectorModal = (company: CompanyEntity) => {
        setSelectedCompany(company);
        setOpenCompanyConnectorModal(true);
    }

    const handleOpenCompanyFeatureModal = (company: CompanyEntity) => {
        setSelectedCompany(company);
        setOpenCompanyFeatureModal(true);
    }

    const handleCloseModal = () => {
        setOpenDeleteModal(false);
        setOpenEditModal(false);
        setOpenCompanyConnectorModal(false);
        setOpenCompanyFeatureModal(false);
        setSelectedCompany(null);
    }

    const handleChangePage = (_, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const onChangeSearch = (searchedVal: string) => {
        if (searchedVal === '') {
            setRows(companies);
            return;
        }

        const filteredRows = companies.filter((row) => {
            return row.companyName.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setRows(filteredRows);
    };

    useEffect(() => {
        loadCompanies();
    }, [loadCompanies]);

    useEffect(() => { 
        if (!user) return;
        
        if (user.role === UserRoleType.ADMIN) {
            setDisableDeleteButton(false);
        }
    }, [user]);

    useEffect(() => {
        onChangeSearch(searched)
    }, [searched]);

    const columns = [
        { id: 'companyName', label: 'Empresa', minWidth: 100 },
        { id: 'companyOwnerEmail', label: 'Email do responsável', minWidth: 100 },
        { id: 'registrationOrigin', label: 'Origem do cadastro', minWidth: 100 },
        {
            id: 'retetionPeriodData',
            label: 'Período de retenção de dados', 
            minWidth: 100,
            format: (value) => dayjs(value).format('DD/MM/YYYY')
        },
        {
            id: 'historyDataSyncProgressInPercentage',
            label: 'Progresso da sincronização de dados', 
            minWidth: 100,
            format: (value) => `${value}%`
        },
        {
            id: 'platformLastAccessDate',
            label: 'Último acesso à plataforma (Conta empresa)', 
            minWidth: 100,
            format: (value) => dayjs(value).format('DD/MM/YYYY')
        },
        {
          id: 'actions',
          label: 'Ações',
          minWidth: 100
        }
    ];

    return (
        <Container>
            <div className='boxModel'>
                <h2 className='titleConsultCompany'>Consultar empresas</h2>
                <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                    <TextField 
                        id="outlined-search" 
                        label="Pesquisar empresa"
                        type="search"
                        value={searched}
                        sx={{ width: '30%', margin: '10px' }}
                        onChange={(e) => setSearched(e.target.value)}
                    />
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                                        >
                                            {column.label}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {!loading && rows.length > 0 &&  rows
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row, index) => {
                                        return (
                                            <TableRow hover role="checkbox" tabIndex={-1} key={index}>
                                                {columns.map((column) => {

                                                    if (column.id === 'actions') {
                                                        return (
                                                            <TableCell key={column.id} sx={{ display: 'flex', justifyContent: 'space-around'}}>
                                                                <Button 
                                                                    variant="outlined"
                                                                    onClick={() => handleOpenEditModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <Edit/>
                                                                </Button>
                                                                <Button
                                                                    disabled={disableDeleteButton} 
                                                                    variant="outlined" 
                                                                    color="error"
                                                                    onClick={() => handleOpenDeleteModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <Delete/>
                                                                </Button>
                                                                <Button 
                                                                    variant="outlined" 
                                                                    color="success"
                                                                    onClick={() => handleOpenCompanyConnectorModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <AccountTree/>
                                                                </Button>
                                                                <Button 
                                                                    variant="outlined" 
                                                                    color="secondary"
                                                                    onClick={() => handleOpenCompanyFeatureModal(row)}
                                                                    sx={{ m: 1 }}
                                                                >
                                                                    <Psychology/>
                                                                </Button>
                                                            </TableCell>
                                                        );
                                                    }
                                                    
                                                    const value = row[column.id];
                                                    return (
                                                        <TableCell key={column.id}>
                                                        {column.format && ['retetionPeriodData', 'historyDataSyncProgressInPercentage'].includes(column.id)
                                                            ? column.format(value)
                                                            : value}
                                                        </TableCell>
                                                    );
                                                })}
                                            </TableRow>
                                        );
                                    })
                                }
                                {!loading && rows.length == 0 && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            Nenhuma empresa cadastrada.
                                        </TableCell>
                                    </TableRow>
                                )}
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">
                                            <CircularProgress />
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 50, 100]}
                        component="div"
                        count={rows.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </div>
            <DeleteCompany
                handleCloseModal={handleCloseModal}
                handleDeleteCompany={handleDeleteCompany}
                selectedCompany={selectedCompany}
                openModal={openDeleteModal}
            />
            {selectedCompany && <EditCompany
                handleCloseModal={handleCloseModal}
                handleEditCompany={handleEditCompany}
                selectedCompany={selectedCompany}
                openModal={openEditModal}
            />}
            {selectedCompany && <CompanyConnector
                handleCloseModal={handleCloseModal}
                selectedCompany={selectedCompany}
                openModal={openCompanyConnectorModal}
                loadCompanies={loadCompanies}
                setActiveFullScreenLoading={setActiveFullScreenLoading}
            />}
            {selectedCompany && <CompanyFeature
                handleCloseModal={handleCloseModal}
                selectedCompany={selectedCompany}
                openModal={openCompanyFeatureModal}
                loadCompanies={loadCompanies}
                setActiveFullScreenLoading={setActiveFullScreenLoading}
            />}
            <FullScreenLoading open={activeFullScreenLoading} />
        </Container>
    )
}