import React from 'react';

import { 
    CircularProgress, 
    Backdrop 
} from '@mui/material';

export const FullScreenLoading = ({open}) => {
    return (
        <Backdrop
            sx={{ color: '#fff', zIndex: 999999 }}
            open={open}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}