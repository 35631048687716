import { 
    AnymarketConnectorSettings,
    EcomplusConnectorSettings,
    FacebookConnectorSettings,
    GoogleAnalyticsConnectorSettings,
    OmnichatConnectorSettings,
    VtexConnectorSettings,
    JetConnectorSettings,
    ShopifyConnectorSettings,
    GoogleAnalytics4ConnectorSettings,
    PluggtoConnectorSettings,
    WakeCommerceConnectorSettings,
    IntelipostConnectorSettings
} from './connectorSettings'

import { CompanyFeature } from '../interfaces'

export class CompanySettings {
    public facebookSettings?: FacebookConnectorSettings | null = null
    public googleAnalyticsSettings?: GoogleAnalyticsConnectorSettings | null = null
    public vtexIntegrationSettings?: VtexConnectorSettings | null = null
    public omnichatSettings?: OmnichatConnectorSettings | null = null
    public anymarketSettings?: AnymarketConnectorSettings | null = null
    public ecomplusSettings?: EcomplusConnectorSettings | null = null
    public jetSettings?: JetConnectorSettings | null = null
    public shopifySettings?: ShopifyConnectorSettings | null = null
    public googleAnalytics4Settings?: GoogleAnalytics4ConnectorSettings | null = null
    public pluggtoSettings?: PluggtoConnectorSettings | null = null
    public wakeCommerceSettings?: WakeCommerceConnectorSettings | null = null
    public intelipostSettings: IntelipostConnectorSettings | null = null
}

export class CompanyEntity {
    public companyId: string = ''
    public companyName: string = ''
    public companyOwnerEmail: string = ''
    public companyUserName: string = ''
    public retetionPeriodData: string = ''
    public searchDataEveryInMinutes: number = 120
    public historyDataSyncProgressInPercentage: number = 0
    public settings: CompanySettings | null = null
    public features: CompanyFeature[] = []
}

export class RegisterCompanyEntity extends CompanyEntity {
    public companyOwnerFirstName: string = ''
    public companyOwnerLastName: string = ''
    public registrationOrigin: string = 'backoffice'

    constructor({
        companyName, 
        companyOwnerFirstName, 
        companyOwnerLastName, 
        companyOwnerEmail,
        companyUserName,
        retetionPeriodData,
    }) {
        super()
        this.companyName = companyName
        this.companyOwnerFirstName = companyOwnerFirstName
        this.companyOwnerLastName = companyOwnerLastName
        this.companyOwnerEmail = companyOwnerEmail
        this.companyUserName = companyUserName
        this.retetionPeriodData = retetionPeriodData
    }
}