export class RegisterPartnerEntity {
    public name: string = ''
    public imageUrl: string = ''
    public description: string = ''
    public expertise: string = ''
    public commercialBenefit: string = ''
    public redirect: string = ''

    constructor({
        name,
        imageUrl,
        description,
        expertise,
        commercialBenefit,
        redirect
    }) {
        this.name = name
        this.imageUrl = imageUrl
        this.description = description
        this.expertise = expertise
        this.commercialBenefit = commercialBenefit
        this.redirect = redirect
    }
}

export class PartnerEntity extends RegisterPartnerEntity {
    public id: string = ''
}