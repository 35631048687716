import React, { createContext, useEffect, useState, useCallback } from 'react';

import{ HttpStatusCode } from 'axios';

import { useNavigate } from 'react-router-dom';

import { Auth, User } from '../../models/interfaces';

import { api, signIn, validateToken } from '../../adapters';

import { toast } from 'react-toastify';

export const AuthContext = createContext<Auth>({} as Auth);

export const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState<User|null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    const handleLogout = () => {
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('access_token');

        api.defaults.headers.Authorization = null;

        navigate('/login');
    };

    const setApiAuthConfig = (userToken: string) => {
        api.defaults.headers.Authorization = `Bearer ${userToken}`;

        api.interceptors.response.use(response => {
            return response;
        }, error => {
            if (error.response.status === HttpStatusCode.Unauthorized) {
                handleLogout();
            }

            return error.response;
        });
    }

    const handleLogin = async (email: string, password: string) => {
        try {
            const loggedUser = await signIn(email, password);

            setUser(loggedUser.user);

            localStorage.setItem('user', JSON.stringify(loggedUser.user));
            localStorage.setItem('access_token', loggedUser.access_token);

            setApiAuthConfig(loggedUser.access_token)

            navigate('/');

            toast.success('Login realizado com sucesso');
        } catch (error) {
            toast.error('Usuário ou senha inválidos');
        }
    };

    const validateUserToken = useCallback(async () => {
        try {
            await validateToken();
        } catch (error) {
            handleLogout();
        }
    }, []);

    useEffect(() => {
        const userStorage = localStorage.getItem('user');
        const userToken = localStorage.getItem('access_token');

        if (userToken && userStorage) {
            setUser(JSON.parse(userStorage));

            setApiAuthConfig(userToken)

            validateUserToken();
        }

        setLoading(false);
    }, []);

    return (
        <AuthContext.Provider value={{ authenticated: !!user, user, loading, handleLogin, handleLogout}}>
            {children}
        </AuthContext.Provider>
    )
}