import React from 'react';

import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle 
} from '@mui/material';

export const DeleteCompany = ({
    handleCloseModal,
    handleDeleteCompany,
    selectedCompany,
    openModal
}) => {
    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="dialogTitleDeleteCompany"
            aria-describedby="dialogDescriptionDeleteCompany"
         >
        <DialogTitle id="dialogTitleDeleteCompany">
          {"Deletar empresa"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialogDescriptionDeleteCompany">
            Caso você confirme, a empresa será deletada permanentemente, junto com todos os seus dados.
            <br />
            <br />
            <strong>Essa ação não pode ser desfeita.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => handleDeleteCompany(selectedCompany.companyId)} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
}