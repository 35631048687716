import React, { useState } from 'react';

import { 
    Button, 
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField
} from '@mui/material';

import { 
  RegisterEngagementRule as RegisterEngagementRuleEntity
} from '../../../models';

import { registerEngagementRule } from '../../../adapters';

import { toast } from 'react-toastify';

export const RegisterEngagementRule = ({
  handleCloseModal,
  openModal,
  setActiveFullScreenLoading
}) => {
  const [ruleName, setRuleName] = useState<string>('');
  const [ruleType, setRuleType] = useState<string>('number');
  const [rulePoints, setRulePoints] = useState<number>(0);
  
  const RULE_TYPE_OPTIONS = [
    { value: 'number', label: 'Numérico' },
    { value: 'boolean', label: 'Verdadeiro/Falso' }
  ];

  const handleClose = () => {
    setRuleName('');
    setRuleType('number');
    setRulePoints(0);
    handleCloseModal();
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (rulePoints <= 0) {
      toast.error('A pontuação da regra de engajamento deve ser maior que zero.');
      return;
    }

    setActiveFullScreenLoading(true);
    
    try {
      const registerEngagementRuleRequest = new RegisterEngagementRuleEntity();
      registerEngagementRuleRequest.name = ruleName;
      registerEngagementRuleRequest.type = ruleType;
      registerEngagementRuleRequest.points = rulePoints;

      await registerEngagementRule(
        registerEngagementRuleRequest
      );

      toast.success('Regra de engajamento cadastrada com sucesso.');

      handleClose();
    } catch (error: any) {
      toast.error(error.message);
    }

    setActiveFullScreenLoading(false);
  }

  return (
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="dialogTitleRegisterEngagementRule"
        aria-describedby="dialogDescriptionRegisterEngagementRule"
      >
        <DialogTitle id="dialogTitleRegisterEngagementRule">
          Cadastrar regra de engajamento
        </DialogTitle>
        <form className="formAddConnector" onSubmit={handleSubmit} >
          <DialogContent
            id="dialogDescriptionRegisterEngagementRule"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          > 
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <TextField
                required
                id="inputRuleName"
                label="Nome da regra"
                value={ruleName}
                onChange={(event) => setRuleName(event.target.value)}
                variant="outlined"
                sx={{ m: 1, minWidth: 400}}
              />
            </FormControl>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <TextField
                required
                id="inputRulePoints"
                label="Pontuação"
                type="number"
                value={rulePoints}
                onChange={(event) => setRulePoints(Number(event.target.value))}
                variant="outlined"
                sx={{ m: 1, minWidth: 400}}
              />
            </FormControl>
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel 
                id="selectEngagementRuleTypeLabel"
                sx={{ m: 1, minWidth: 400}}
              >
                Tipo de regra
              </InputLabel>
              <Select
                  required
                  labelId="selectEngagementRuleTypeLabel"
                  id="selectEngagementRuleType"
                  value={ruleType}
                  label="Tipo de regra"
                  onChange={(event) => setRuleType(event.target.value)}
                  sx={{ m: 1, minWidth: 400}}
              >
                  {RULE_TYPE_OPTIONS.map((rule, index) => (
                      <MenuItem key={index} value={rule.value}>{rule.label}</MenuItem>
                  ))}
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button 
              variant="contained" 
              type='submit'
              autoFocus
            >
              Cadastrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
  );
}