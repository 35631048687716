import React, { useContext } from 'react';

import { AuthContext } from '../../contexts';

import { Container } from "../../components";

import { Typography } from '@mui/material';

export const Home = () => {
    const { user } = useContext(AuthContext);
    
    return (
        <Container>
            <div>
                <Typography>Bem vindx {user ? user.name : ''} ao backoffice da Biso!</Typography>
                <br />
                <Typography>É Através desse portal que você poderá cadastrar um novo cliente, consultar dados de clientes e atualizar dados de clientes da Biso.</Typography>
                <br />
                <Typography>Para começar, selecione a opção desejada no menu superior.</Typography>
                <br />
                <Typography>Se precisar de suporte para algum problema que esteja ocorrendo, entre em contato com o time de suporte técnico através do canal no Discord.</Typography>
            </div>
        </Container>
    )
}