import { CompanySettings, ConnectorType } from "..";

export enum FieldType {
    TEXT = "text",
    SWITCH = "switch",
    NUMBER = "number",
    MULTI_NUMBER = "multi_number" 
}

export interface ConnectorConfig {
    displayName: string;
    type: ConnectorType;
    settingsFields: { key: string, displayName: string, type: FieldType, dependentBoolFieldKey?: string }[];
    key: string;
}

export class ConnectorConfigDictionary {
    private static readonly dictionary = {
        facebookSettings: {
            displayName: 'Facebook Ads',
            type: ConnectorType.FACEBOOK_ADS,
            settingsFields: [
                { key: 'apiKey', displayName: 'ID da conta de anúncio', type: FieldType.TEXT }
            ],
            key: 'facebookSettings'
        },
        googleAnalyticsSettings: {
            displayName: 'Google Analytics',
            type: ConnectorType.GOOGLE_ANALYTICS,
            settingsFields: [
                { key: 'apiKey', displayName: 'ID do analytics', type: FieldType.TEXT }
            ],
            key: 'googleAnalyticsSettings'
        },
        vtexIntegrationSettings: {
            displayName: 'VTEX',
            type: ConnectorType.VTEX,
            settingsFields: [
                { key: 'accountName', displayName: 'Nome da conta da loja/ou subloja', type: FieldType.TEXT },
                { key: 'apiKey', displayName: 'API Key', type: FieldType.TEXT },
                { key: 'token', displayName: 'Token', type: FieldType.TEXT },
                { key: 'hasMultistore', displayName: 'Tem multiloja?', type: FieldType.SWITCH },
                { key: 'multistoreConfigSalesChannelsIds', displayName: 'IDs dos canais de vendas da loja/ou subloja.', type: FieldType.MULTI_NUMBER, dependentBoolFieldKey: "hasMultistore"}
            ],
            key: 'vtexIntegrationSettings'
        },
        omnichatSettings: {
            displayName: 'Omnichat',
            type: ConnectorType.OMNICHAT,
            settingsFields: [
                { key: 'apiKey', displayName: 'API Key', type: FieldType.TEXT },
                { key: 'apiSecret', displayName: 'API Secret', type: FieldType.TEXT }
            ],
            key: 'omnichatSettings'
        },
        anymarketSettings: {
            displayName: 'Anymarket',
            type: ConnectorType.ANYMARKET,
            settingsFields: [
                { key: 'apiKey', displayName: 'API Key', type: FieldType.TEXT }
            ],
            key: 'anymarketSettings'
        },
        ecomplusSettings: {
            displayName: 'E-Com Plus',
            type: ConnectorType.ECOMPLUS,
            settingsFields: [
                { key: 'storeId', displayName: 'ID da loja', type: FieldType.TEXT },
                { key: 'myId', displayName: 'Meu ID', type: FieldType.TEXT },
                { key: 'apiKey', displayName: 'API Key', type: FieldType.TEXT }
            ],
            key: 'ecomplusSettings'
        },
        jetSettings: {
            displayName: 'Jet',
            type: ConnectorType.JET,
            settingsFields: [
                { key: 'storeId', displayName: 'ID da loja', type: FieldType.TEXT },
                { key: 'integrationKey', displayName: 'Chave de integração', type: FieldType.TEXT },
                { key: 'usernameProductApi', displayName: 'Usuário da API de produtos', type: FieldType.TEXT },
                { key: 'passwordProductApi', displayName: 'Senha da API de produtos', type: FieldType.TEXT },
                { key: 'usernameOrderApi', displayName: 'Usuário da API de pedidos', type: FieldType.TEXT },
                { key: 'passwordOrderApi', displayName: 'Senha da API de pedidos', type: FieldType.TEXT }
            ],
            key: 'jetSettings'
        },
        shopifySettings: {
            displayName: 'Shopify',
            type: ConnectorType.SHOPIFY,
            settingsFields: [
                { key: 'apiKey', displayName: 'API Key', type: FieldType.TEXT },
                { key: 'storeUri', displayName: 'URI da loja', type: FieldType.TEXT }
            ],
            key: 'shopifySettings'
        },
        googleAnalytics4Settings: {
            displayName: 'Google Analytics 4',
            type: ConnectorType.GOOGLE_ANALYTICS_4,
            settingsFields: [
                { key: 'apiKey', displayName: 'ID do analytics 4', type: FieldType.TEXT }
            ],
            key: 'googleAnalytics4Settings'
        },
        pluggtoSettings: {
            displayName: 'Plugg.to',
            type: ConnectorType.PLUGGTO,
            settingsFields: [
                { key: 'username', displayName: 'Usuário', type: FieldType.TEXT },
                { key: 'password', displayName: 'Senha', type: FieldType.TEXT },
                { key: 'clientId', displayName: 'Client ID', type: FieldType.TEXT },
                { key: 'clientSecret', displayName: 'Client Secret', type: FieldType.TEXT }
            ],
            key: 'pluggtoSettings'
        },
        wakeCommerceSettings: {
            displayName: 'Wake Commerce',
            type: ConnectorType.WAKE_COMMERCE,
            settingsFields: [
                { key: 'apiKey', displayName: 'API Key', type: FieldType.TEXT },
            ],
            key: 'wakeCommerceSettings'
        },
        intelipostSettings: {
            displayName: "Intelipost",
            type: ConnectorType.INTELIPOST,
            settingsFields: [
                {key:'apiKey', displayName: 'API key', type: FieldType.TEXT},
                {key: 'clientId', displayName: 'Client Id', type: FieldType.TEXT}
                
            ],
            key: 'intelipostSettings'
        }
    }

    public static getConfigForNotEnabledConnector(companySettings: CompanySettings): ConnectorConfig[] {
        if (!companySettings) {
            return Object.values(this.dictionary);
        }

        const notEnabledConnectors: ConnectorConfig[] = [];

        for (const key of Object.keys(this.dictionary)) {
            if (companySettings[key] && companySettings[key] !== null) {
                continue;
            }

            notEnabledConnectors.push(this.dictionary[key]);
        }

        return notEnabledConnectors;
    }

    public static hasConnectorsEnabled(companySettings: CompanySettings): boolean {
        if (!companySettings) {
            return false;
        }

        for (const key of Object.keys(this.dictionary)) {
            if (companySettings[key] && companySettings[key] !== null) {
                return true;
            }
        }

        return false;
    }

    public static getConfigByType(type: ConnectorType): ConnectorConfig | null {
        const config = Object.values(this.dictionary).find(config => config.type === type);

        return config || null;
    }

    public static getConfigByKey(key: string): ConnectorConfig | null {
        const config = Object.values(this.dictionary).find(config => config.key === key);

        return config || null;
    }
}