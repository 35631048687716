import React, { useCallback, useEffect, useState } from 'react';

import { 
    Button, 
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Switch,
    FormGroup,
    FormControlLabel
} from '@mui/material';

import { 
  EngagementRule
} from '../../../models';

import { 
  updateEngagementRuleActive, 
  updateEngagementRulePoints, 
  getEngagementRules 
} from '../../../adapters';

import { toast } from 'react-toastify';

export const EditEngagementRule = ({
  handleCloseModal,
  openModal,
  setActiveFullScreenLoading
}) => {
  const [engagementRules, setEngagementRules] = useState<EngagementRule[]>([]);
  const [selectedEngagementRule, setSelectedEngagementRule] = useState<EngagementRule | null>(null);
  const [selectedRuleId, setSelectedRuleId] = useState<string>('');
  const [selectedRulePoints, setSelectedRulePoints] = useState<number>(0);
  const [selectedActive, setSelectedActive] = useState<boolean>(false);
  
  const RULE_TYPE_OPTIONS = [
    { value: 'number', label: 'Numérico' },
    { value: 'boolean', label: 'Verdadeiro/Falso' }
  ];

  const loadEngagementRules = useCallback(async () => {
    setActiveFullScreenLoading(true);

    try {
      const engagementRules = await getEngagementRules();

      setEngagementRules(engagementRules);
    } catch (error: any) {
      toast.error("Ocorreu um erro ao carregar as regras de engajamento.");
    }

    setActiveFullScreenLoading(false);
  }, []);

  const handleClose = () => {
    setSelectedRuleId('');
    setSelectedRulePoints(0);
    setSelectedActive(false);
    setEngagementRules([]);
    handleCloseModal();
  }

  const handleChangeRule = (ruleId: string) => {
    const selectedEngagementRule = engagementRules.find(rule => rule.id === ruleId) || null;

    if (selectedEngagementRule) {
      setSelectedEngagementRule(selectedEngagementRule);
      setSelectedRuleId(selectedEngagementRule.id);
      setSelectedRulePoints(selectedEngagementRule.points);
      setSelectedActive(selectedEngagementRule.active);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (selectedRulePoints <= 0) {
      toast.error('A pontuação da regra de engajamento deve ser maior que zero.');
      return;
    }

    setActiveFullScreenLoading(true);
    
    try {
      if (selectedEngagementRule && selectedEngagementRule.points !== selectedRulePoints) {
        await updateEngagementRulePoints(
          selectedRuleId,
          selectedRulePoints
        );
      }

      if (selectedEngagementRule && selectedEngagementRule.active !== selectedActive) {
        await updateEngagementRuleActive(
          selectedRuleId,
          selectedActive
        );
      }

      toast.success('Regra de engajamento atualizada com sucesso.');

      handleClose();
    } catch (error: any) {
      toast.error(error.message);
    }

    setActiveFullScreenLoading(false);
  }

  useEffect(() => {
    loadEngagementRules();
  }, [loadEngagementRules]);

  return (
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="dialogTitleEditEngagementRule"
        aria-describedby="dialogDescriptionEditEngagementRule"
      >
        <DialogTitle id="dialogTitleEditEngagementRule">
          Editar regra de engajamento
        </DialogTitle>
        <form className="formAddConnector" onSubmit={handleSubmit} >
          <DialogContent
            id="dialogDescriptionEditEngagementRule"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          > 
            <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel 
                id="selectEngagementRule"
                sx={{ m: 1, minWidth: 400}}
              >
                Regra de Engajamento
              </InputLabel>
              <Select
                  required
                  labelId="selectEngagementRule"
                  id="selectEngagementRule"
                  value={selectedRuleId}
                  label="Regra de Engajamento"
                  onChange={(event) => handleChangeRule(event.target.value)}
                  sx={{ m: 1, minWidth: 400}}
              >
                  {engagementRules.map((rule, index) => (
                      <MenuItem key={index} value={rule.id}>{rule.name}</MenuItem>
                  ))}
              </Select>
            </FormControl>
            {selectedEngagementRule && (
              <>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                  <TextField
                    required
                    id="inputRulePoints"
                    label="Pontuação"
                    type="number"
                    value={selectedRulePoints}
                    onChange={(event) => setSelectedRulePoints(Number(event.target.value))}
                    variant="outlined"
                    sx={{ m: 1, minWidth: 400}}
                  />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel 
                  id="selectEngagementRuleTypeLabel"
                  sx={{ m: 1, minWidth: 400}}
                >
                  Tipo de regra
                </InputLabel>
                <Select
                    required
                    labelId="selectEngagementRuleTypeLabel"
                    id="selectEngagementRuleType"
                    value={selectedEngagementRule.type}
                    label="Tipo de regra"
                    disabled
                    sx={{ m: 1, minWidth: 400}}
                >
                    {RULE_TYPE_OPTIONS.map((rule, index) => (
                        <MenuItem key={index} value={rule.value}>{rule.label}</MenuItem>
                    ))}
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <FormGroup>
                  <FormControlLabel 
                    control={
                      <Switch  
                        checked={selectedActive}
                        onChange={(event) => setSelectedActive(event.target.checked)}
                      />
                    } 
                    label="Ativo"
                    sx={{ m: 1, minWidth: 400 }}
                  />
                </FormGroup>
              </FormControl>
            </>
          )}
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button 
              variant="contained" 
              type='submit'
              autoFocus
              disabled={
                !selectedEngagementRule || 
                (selectedEngagementRule && selectedEngagementRule.points === selectedRulePoints 
                  && selectedEngagementRule.active === selectedActive
                )
              }
            >
              Confirmar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
  );
}