import React from 'react';

import { 
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle 
} from '@mui/material';

export const DeletePartner = ({
    handleCloseModal,
    handleDeletePartner,
    selectedPartner,
    openModal
}) => {
    return (
        <Dialog
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="dialogTitleDeletePartner"
            aria-describedby="dialogDescriptionDeletePartner"
         >
        <DialogTitle id="dialogTitleDeletePartner">
          {"Deletar parceiro"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialogDescriptionDeletePartner">
            Caso você confirme, o parceiro será deletada permanentemente, junto com todos os seus dados.
            <br />
            <br />
            <strong>Essa ação não pode ser desfeita.</strong>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleCloseModal}>Cancelar</Button>
          <Button variant="contained" onClick={() => handleDeletePartner(selectedPartner.id)} autoFocus>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    );
}