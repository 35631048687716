import React, { useState } from 'react';

import { 
    Button, 
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Switch,
    FormGroup,
    FormControlLabel
} from '@mui/material';

import { MultiNumberInput } from '../../../../components';

import { 
  ConnectorConfigDictionary, ConnectorConfig, ConnectorType, 
  CompanySettings, FieldType
} from '../../../../models';

import { addConnector, getCompanyConnectorUserModule } from '../../../../adapters';

import { toast } from 'react-toastify';

export const AddConnector = ({
    handleCloseAllModal,
    handleCloseModal,
    openModal,
    selelectedCompanyId,
    selectedCompany,
    loadCompanies,
    setActiveFullScreenLoading
}) => {
  const [connectorOptions, _] = useState(ConnectorConfigDictionary.getConfigForNotEnabledConnector(selectedCompany.settings))
  const [selectedConnectorConfig, setSelectedConnectorConfig] = useState<ConnectorConfig | null>(null);
  const [connectorSettings, setConnectorSettings] = useState({})

  const handleChangeConnectorOption = async (event) => {
    const connectorConfig = ConnectorConfigDictionary.getConfigByType(event.target.value)
    setConnectorSettings({})

    if (connectorConfig && [ConnectorType.FACEBOOK_ADS, ConnectorType.GOOGLE_ANALYTICS_4].includes(connectorConfig.type)) {
      const companyConnectorUserModule = await getCompanyConnectorUserModuleBySelectedConnector(connectorConfig.type);
      if (companyConnectorUserModule) {
        setConnectorSettings({apiKey: companyConnectorUserModule.settings.apiKey.replace('act_', '')})
      }
    }

    setSelectedConnectorConfig(connectorConfig);
  }

  const handleClose = () => {
    setSelectedConnectorConfig(null);
    setConnectorSettings({});
    handleCloseModal();
  }

  const getCompanyConnectorUserModuleBySelectedConnector = async (selectedConnectorType: string) => {
    try {
      const companyConnectorUserModule = await getCompanyConnectorUserModule(selelectedCompanyId, selectedConnectorType);
      return companyConnectorUserModule
    } catch (error: any) {
      toast.error(error.message);
      return null
    }
  }

  const getCompanySettingsForRequest = (connectorConfig: ConnectorConfig): CompanySettings => {
    const companySettings = new CompanySettings();
    let connectorSettingsTemp = {...connectorSettings}

    // TODO: Refactor this
    // Facebook Ads API Key is in the format act_{API_KEY}
    if (connectorConfig.type === ConnectorType.FACEBOOK_ADS) {
      connectorSettingsTemp["apiKey"] = `act_${connectorSettings["apiKey"]}`
    }

    if(connectorConfig.type === ConnectorType.VTEX) {
      if (connectorSettingsTemp.hasOwnProperty("hasMultistore") && !connectorSettingsTemp["hasMultistore"]) {
        connectorSettingsTemp["multistoreConfigSalesChannelsIds"] = []
      } else if (connectorSettingsTemp.hasOwnProperty("hasMultistore") && connectorSettingsTemp["hasMultistore"]) {
        if (!connectorSettingsTemp.hasOwnProperty("multistoreConfigSalesChannelsIds") || connectorSettingsTemp["multistoreConfigSalesChannelsIds"].length === 0) {
          throw new Error("Não foi possível adicionar o conector. Informe ao menos um ID de canal de vendas para a configuração de multiloja. Se você já informou, verifique se ao digitar você confirmou apertando a tecla Enter.")
        }
      }
    }

    companySettings[connectorConfig.key] = connectorSettingsTemp;

    return companySettings
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!selectedConnectorConfig) {
      toast.error('Selecione uma fonte de dados!');
      return;
    }

    setActiveFullScreenLoading(true);
    
    try {
      const companySettings = getCompanySettingsForRequest(selectedConnectorConfig);

      await addConnector(selelectedCompanyId, selectedConnectorConfig.type, companySettings);

      toast.success('Fonte de dados adicionada com sucesso!');

      handleClose();
      handleCloseAllModal();

      loadCompanies();
    } catch (error: any) {
      toast.error(error.message);

      handleClose(); 
    }

    setActiveFullScreenLoading(false);
  }

  return (
      <Dialog
        open={openModal}
        onClose={handleClose}
        aria-labelledby="dialogTitleAddConnector"
        aria-describedby="dialogDescriptionAddConnector"
      >
        <DialogTitle id="dialogTitleAddConnector">
          {"Adicionar fonte de dados"}
        </DialogTitle>
        <form className="formAddConnector" onSubmit={handleSubmit} >
          <DialogContent
            id="dialogDescriptionAddConnector"
            sx={{
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            { connectorOptions.length === 0 && (<p>Não há fontes de dados disponíveis para adicionar.</p>) }
            { connectorOptions.length > 0 && 
              (<FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="selectConnectorLabel">Conexão</InputLabel>
                <Select
                    required
                    labelId="selectConnectorLabel"
                    id="selectConnector"
                    value={selectedConnectorConfig ? selectedConnectorConfig.type : ''}
                    label="Conexão"
                    onChange={handleChangeConnectorOption}
                    sx={{ m: 1, minWidth: 400 }}
                >
                    {connectorOptions.map(connector => (
                        <MenuItem key={connector.type} value={connector.type}>{connector.displayName}</MenuItem>
                    ))}
                </Select>
                {selectedConnectorConfig && selectedConnectorConfig.settingsFields.map(setting => {
                  const hasDependentBoolField = !!setting.dependentBoolFieldKey
                  const showThisField = hasDependentBoolField 
                    ? connectorSettings[setting.dependentBoolFieldKey || ""]
                    : true
      
                  if (!showThisField) { return }

                  if (setting.type === FieldType.TEXT || setting.type === FieldType.NUMBER) {
                    return (
                      <TextField
                          key={`${setting.key}${setting.displayName}`}
                          defaultValue={setting.type === FieldType.NUMBER ? 0 : ""}
                          type={setting.type}
                          required
                          label={setting.displayName}
                          value={connectorSettings[setting.key]}
                          onChange={(event) => setConnectorSettings({...connectorSettings, [setting.key]: event.target.value})}
                          sx={{ m: 1, minWidth: 400 }}
                      />
                    )
                  } else if (setting.type === FieldType.SWITCH) {
                    return (
                      <FormGroup key={`${setting.key}`}>
                        <FormControlLabel 
                          control={
                            <Switch  
                              key={`${setting.key}${setting.displayName}`}
                              checked={connectorSettings[setting.key]}
                              onChange={(event) => setConnectorSettings({
                                ...connectorSettings, 
                                [setting.key]: event.target.checked,
                              })}
                              sx={{ m: 1 }}
                            />
                          } 
                          label={setting.displayName}
                        />
                      </FormGroup>
                    )
                  } else if (setting.type === FieldType.MULTI_NUMBER) {
                    return (
                      <MultiNumberInput 
                        key={`${setting.key}${setting.displayName}`}
                        label={setting.displayName}
                        value={connectorSettings[setting.key]}
                        onChange={(value) => setConnectorSettings({...connectorSettings, [setting.key]: value})}
                        required
                      />
                    )
                  }
                })}
              </FormControl>)
            }
          </DialogContent>
          <DialogActions sx={{ m: 1 }}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button 
              variant="contained" 
              type='submit'
              autoFocus
              disabled={connectorOptions.length === 0}
            >
              Adicionar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
  );
}