import React, { useState, useEffect, useCallback } from 'react';

import { 
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
   Switch
} from '@mui/material';

import { getFeatures, enableOrDisableCompanyFeature } from '../../../adapters';

import { Feature, CompanyFeature as CompanyFeatureInterface } from '../../../models';

import { toast } from 'react-toastify';

interface FeatureRow {
  featureId: string;
  enabled: boolean;
  index: number;
}

export const CompanyFeature = ({
    handleCloseModal,
    selectedCompany,
    openModal,
    loadCompanies,
    setActiveFullScreenLoading
}) =>  {
  const [features, setFeatures] = useState<Feature[]>([]);
  const [loadingFeatures, setLoadingFeatures] = useState(false);

  const getFeaturesList = useCallback(async () => {
    setActiveFullScreenLoading(true);
    setLoadingFeatures(true);

    try {
      const features = await getFeatures();
      setFeatures(features);
    } catch (error) {
      toast.error('Não foi possível carregar as funcionalidades. Tente novamente mais tarde.');
    }

    setActiveFullScreenLoading(false);
    setLoadingFeatures(false);
  }, []);

  const enableOrDisableFeature = async (featureId: string, enabled: boolean) => {
    setActiveFullScreenLoading(true);

    try {
      await enableOrDisableCompanyFeature(selectedCompany.companyId, featureId, enabled);
      
      toast.success(`Funcionalidade ${enabled ? "habilitada" : "desabilitada"} com sucesso.`);

      handleCloseAllModal();
      
      loadCompanies();
    } catch (error) {
      toast.error(`Não foi possível ${enabled ? "habilitar" : "desabilitar"} a funcionalidade. Tente novamente mais tarde.`);
    }

    setActiveFullScreenLoading(false);
  } 

  const getFeatureName = (featureId: string) => {
    const feature = features.find(feature => feature.id === featureId);
    return feature ? feature.name : '';
  }

  const getFeaturesNotOnTheList = (companyFeatures: CompanyFeatureInterface[]) => {
    const featuresNotOnTheList = features.filter(feature => {
      return !companyFeatures.some(companyFeature => companyFeature.featureId === feature.id);
    });

    return featuresNotOnTheList;
  }

  const handleCloseAllModal = () => {
    setFeatures([]);
    setLoadingFeatures(false);
    handleCloseModal();
  }

  useEffect(() => {
    getFeaturesList();
  }, []);

  const columns = [
    { 
      id: 'featureId', 
      label: 'Funcionalidade', 
      minWidth: 100
    },
    {
      id: 'actions',
      label: 'Habilitado?',
      minWidth: 100
    }
  ];

  const renderRow = (row: FeatureRow) => {
    return (
      <TableRow hover role="checkbox" tabIndex={-1} key={row.index}>
          {columns.map((column) => {
              if (column.id === 'actions') {
                  return (
                      <TableCell key={column.id} sx={{ display: 'flex', justifyContent: 'space-around'}}>
                          <Switch
                            checked={row.enabled}
                            onChange={(event) => enableOrDisableFeature(row.featureId, event.target.checked)}
                          />
                      </TableCell>
                  );
              }

              if (column.id === 'featureId') {
                return (
                  <TableCell key={column.id}>
                      {getFeatureName(row.featureId)}
                  </TableCell>
                );
              }

              return
          })}
      </TableRow>
    );
  }

  return ( 
    <>
      <Dialog
        open={openModal && !loadingFeatures}
        onClose={handleCloseAllModal}
        aria-labelledby="dialogTitleCompanyConnector"
        aria-describedby="dialogDescriptionCompanyConnector"
      >
        <DialogTitle id="dialogTitleCompanyConnector">
          {"Configuração de funcionalidades"}
        </DialogTitle>
        <DialogContent
          id="dialogDescriptionCompanyConnector"
          sx={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <div className="featureList">
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                      <TableRow>
                          {columns.map((column) => (
                              <TableCell
                                  key={column.id}
                                  style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                              >
                                  {column.label}
                              </TableCell>
                          ))}
                      </TableRow>
                  </TableHead>
                  <TableBody>
                  {features.length > 0 && selectedCompany.features && Object.keys(selectedCompany.features).map((key, index) => {
                    return renderRow({
                      featureId: selectedCompany.features[key].featureId,
                      enabled: selectedCompany.features[key].enabled,
                      index
                    })
                  })}
                  {features.length > 0 && getFeaturesNotOnTheList(selectedCompany.features).map((feature, index) => {
                    return renderRow({
                      featureId: feature.id,
                      enabled: false,
                      index
                    })
                  })}
                  {features.length === 0 && (
                      <TableRow>
                          <TableCell colSpan={6} align="center">
                              Nenhuma funcionalidade configurada
                          </TableCell>
                      </TableRow>
                  )}
                  </TableBody>
              </Table>
            </TableContainer>
        </div>  
        </DialogContent>
        <DialogActions sx={{ m: 1 }}>
          <Button onClick={handleCloseAllModal}>Fechar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}