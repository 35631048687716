import React, { useState } from 'react';

import dayjs from 'dayjs';

import { useNavigate } from "react-router-dom";

import { Button, FormControl, TextField } from '@mui/material';

import { DateField, LocalizationProvider } from '@mui/x-date-pickers';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { Container, FullScreenLoading } from "../../components";

import { RegisterCompanyEntity } from '../../models';

import { toast } from 'react-toastify';

import { registerCompany } from '../../adapters';

import './index.css';

export const RegisterCompany = () => {
    const navigate = useNavigate();

    const [companyOwnerFirstName, setCompanyOwnerFirstName] = useState('');
    const [companyOwnerLastName, setCompanyOwnerLastName] = useState('');
    const [companyOwnerEmail, setCompanyOwnerEmail] = useState('')
    const [companyName, setCompanyName] = useState('');
    const [companyUserName, setCompanyUserName] = useState('');
    const [retetionPeriodData, setRetetionPeriodData] = useState(dayjs(Date.now()));
    const [activeFullScreenLoading, setActiveFullScreenLoading] = useState<boolean>(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        setActiveFullScreenLoading(true);

        try {
            const companyData = new RegisterCompanyEntity({
                companyOwnerFirstName,
                companyOwnerLastName,
                companyOwnerEmail,
                companyName,
                companyUserName,
                retetionPeriodData: retetionPeriodData.format('YYYY-MM-DD')
            })

            await registerCompany(companyData);

            toast.success('Empresa cadastrada com sucesso!');

            navigate('/');
        } catch (error) {
            toast.error('Erro ao cadastrar empresa, tente novamente.');
        }

        setActiveFullScreenLoading(false);
    }

    return (
        <Container>
            <form className="boxModel formRegisterCompany" onSubmit={handleSubmit} >
                <h2 className='titleRegisterCompany'>Cadastro de empresa</h2>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="firstName" 
                        label="Nome do responsável"
                        type='text'
                        variant="outlined" 
                        value={companyOwnerFirstName}
                        onChange={(event) => setCompanyOwnerFirstName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="lastName" 
                        label="Sobrenome do responsável"
                        type='text'
                        variant="outlined" 
                        value={companyOwnerLastName}
                        onChange={(event) => setCompanyOwnerLastName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="email" 
                        label="Email do responsável"
                        type='email'
                        variant="outlined" 
                        value={companyOwnerEmail}
                        onChange={(event) => setCompanyOwnerEmail(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="companyName" 
                        label="Nome da empresa"
                        type='text'
                        variant="outlined" 
                        value={companyName}
                        onChange={(event) => setCompanyName(event.target.value)}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <TextField 
                        required
                        id="companyName" 
                        label="Login da empresa"
                        type='text'
                        variant="outlined" 
                        value={companyUserName}
                        onChange={(event) => setCompanyUserName(event.target.value.replace(/\s/g, '').toLowerCase())}
                    />
                </FormControl>
                <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateField
                            required
                            format='DD/MM/YYYY'
                            label="Período de retenção de dados" 
                            value={retetionPeriodData}
                            onChange={(newValue) => setRetetionPeriodData(dayjs(newValue))}
                        />
                    </LocalizationProvider>
                </FormControl>
                <div className="actions">
                    <Button variant="contained" type="submit">Cadastrar</Button>
                </div> 
            </form>
            <FullScreenLoading open={activeFullScreenLoading} />
        </Container>
    )
}