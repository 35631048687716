import React from 'react';

import { 
    Button, 
    Dialog, 
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    TextField,
    FormGroup,
    FormControlLabel,
    Switch
} from '@mui/material';

import { MultiNumberInput } from '../../../../components';

import { FieldType } from '../../../../models';

export const ShowConnectorInfo = ({
    handleCloseModal,
    openModal,
    connectorConfig,
    connectorInfo
}) => {
  return (
      <Dialog
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="dialogTitleShowConnectorInfo"
          aria-describedby="dialogDescriptionShowConnectorInfo"
        >
      <DialogTitle id="dialogTitleShowConnectorInfo">
        {"Informações da conexão"}
      </DialogTitle>
      <DialogContent
        id="dialogDescriptionShowConnectorInfo"
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          {connectorConfig && connectorConfig.settingsFields.map(fieldSetting => {
            const hasDependentBoolField = !!fieldSetting.dependentBoolFieldKey
            const showThisField = hasDependentBoolField 
              ? connectorInfo[fieldSetting.dependentBoolFieldKey]
              : true

            if (!showThisField) { return }
            
            if (fieldSetting.type === FieldType.TEXT || fieldSetting.type === FieldType.NUMBER) {
              return (
                <TextField
                  disabled
                  type={fieldSetting.type}
                  key={`${fieldSetting.key}${fieldSetting.displayName}`}
                  label={fieldSetting.displayName}
                  value={connectorInfo[fieldSetting.key]}
                  sx={{ m: 1, minWidth: 400 }}
                />
              )
            } else if (fieldSetting.type === FieldType.SWITCH) {
              return (
                <FormGroup key={`${fieldSetting.key}`}>
                  <FormControlLabel 
                    control={
                      <Switch
                        disabled
                        key={`${fieldSetting.key}${fieldSetting.displayName}`}
                        checked={connectorInfo[fieldSetting.key]}
                        sx={{ m: 1 }}
                      />
                    } 
                    label={fieldSetting.displayName}
                  />
                </FormGroup>
              )
            } else if (fieldSetting.type === FieldType.MULTI_NUMBER) {
              return (
                <MultiNumberInput 
                  key={`${fieldSetting.key}${fieldSetting.displayName}`}
                  label={fieldSetting.displayName}
                  value={connectorInfo[fieldSetting.key]}
                  disabled
                />
              )
            }
          })}
        </FormControl>
      </DialogContent>
      <DialogActions sx={{ m: 1 }}>
        <Button onClick={handleCloseModal}>Fechar</Button>
      </DialogActions>
    </Dialog>
  );
}