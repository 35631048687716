export enum ConnectorType {
    VTEX = "vtex_integration_settings",
    GOOGLE_ANALYTICS = "google_analytics_settings",
    FACEBOOK_ADS = "facebook_settings",
    GOOGLE_ANALYTICS_4 = "google_analytics_4_settings",
    OMNICHAT = "omnichat_settings",
    ANYMARKET = "anymarket_settings",
    ECOMPLUS = "ecomplus_settings",
    JET = "jet_settings",
    SHOPIFY = "shopify_settings",
    PLUGGTO = "pluggto_settings",
    WAKE_COMMERCE = "wake_commerce_settings",
    INTELIPOST = "intelipost_settings"
}