import React, { useContext } from 'react'
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom'

import { AuthProvider, AuthContext } from './contexts';

import { Menu } from './components'

import { Login, Home, RegisterCompany, ConsultCompany, RegisterUser, Partner, Engagement } from './pages'

export const appPathPrivateRoutesDict = {
  home: '/',
  registerCompany: '/registerCompany',
  consultCompany: '/consultCompany',
  registerUser: '/registerUser',
  partner: '/partner',
  engagement: '/engagement'
}

export const AppRoutes = () => {
  const Private = ({ children }) => {
    const { authenticated, loading } = useContext(AuthContext)

    if (loading) {
      return <div>Carregando...</div>
    }

    if (authenticated) {
      return children
    }

    return <Navigate to="/login" />
  }

  return (
    <BrowserRouter>
      <AuthProvider>
        <Private><Menu /></Private>
        <Routes>
          <Route element={<Login/>} path="/login" />
          <Route element={<Private><Home/></Private>} path={appPathPrivateRoutesDict.home} />
          <Route element={<Private><RegisterCompany/></Private>} path={appPathPrivateRoutesDict.registerCompany} />
          <Route element={<Private><ConsultCompany/></Private>} path={appPathPrivateRoutesDict.consultCompany} />
          <Route element={<Private><RegisterUser/></Private>} path={appPathPrivateRoutesDict.registerUser} />
          <Route element={<Private><Partner/></Private>} path={appPathPrivateRoutesDict.partner} />
          <Route element={<Private><Engagement/></Private>} path={appPathPrivateRoutesDict.engagement} />
          <Route element={<Navigate to={appPathPrivateRoutesDict.home} />} path="*" />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  )
}